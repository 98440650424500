import {Link} from 'react-router-dom';

interface ErrorFallbackProps {
  linkTo?: string;
}

export default function ErrorFallback({linkTo = '/'}: ErrorFallbackProps) {
	return (
		<>
			<main className="fixed w-full left-0 right-0 h-full justify-between m-auto">
				<div className="fixed z-10 left-0 right-0 flex-shrink-0 py-16 mx-auto max-w-7xl px-6">
					<p className="text-base font-semibold text-primary-40">Hmm.</p>
					<h1 className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl dark:text-white">There was a Problem.</h1>
					<p className="mt-2 text-base text-variant-30 dark:text-variant-70"></p>
					<div className="mt-6">
						<a href={linkTo} className="text-base font-medium text-primary-40 hover:text-primary-50">
							<span aria-hidden="true"> &larr;</span>
							Go back
						</a>
					</div>
				</div>

				<div className="fixed bottom-0 left-0 right-0 max-h-none h-full bg-not-found bg-center bg-cover isolate grayscale"/>
			</main>

		</>
	);
}