import { DatePickerProps, Popover, Dialog, ValidationResult, DatePicker, Label, Group, DateInput, DateSegment, Button, Calendar, Heading, CalendarGrid, CalendarCell } from 'react-aria-components';
import { CalendarDaysIcon, CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { DateValue } from 'react-aria';

interface DateSelectorProps<T extends DateValue> extends DatePickerProps<T> {
	label?: string;
	description?: string;
	errorMessage?: string | ((validation: ValidationResult) => string);
}
export default function DateSelector<T extends DateValue>({ label, description, errorMessage, ...props }: DateSelectorProps<T>) {
	return (
		<DatePicker {...props} className={'relative'}>
			<Label className={'text-neutral-50'}>{label}</Label>
			<Group className={'flex justify-between gap-5 p-2 border border-neutral-70 rounded'}>
				<DateInput className={'flex'}>
					{(segment) => <DateSegment segment={segment} />}
				</DateInput>
				<Button><CalendarDaysIcon className={'h-6 aspect-square text-neutral-50'} /></Button>
			</Group>
			<Popover className={'bg-neutral-90 p-3 shadow rounded-lg border border-neutral-80'}>
				<Dialog>
					<Calendar className={'text-neutral-5'}>
						<header className={'flex w-full justify-between p-2 my-2'}>
							<Button slot="previous"><ChevronLeftIcon className={'h-6 aspect-square'} /></Button>
							<Heading />
							<Button slot="next"><ChevronRightIcon className={'h-6 aspect-square'} /></Button>
						</header>
						<CalendarGrid className={'text-center'}>
							{(date) => <CalendarCell className={'data-[disabled]:text-neutral-70 data-[disabled]:hover:bg-neutral-90 data-[outside-month]:hidden data-[selected=true]:bg-primary-40 data-[selected]:text-neutral-90 h-10 aspect-square p-2 rounded-full hover:bg-neutral-80'} date={date} />}
						</CalendarGrid>
					</Calendar>
				</Dialog>
			</Popover>
		</DatePicker>
	);
}