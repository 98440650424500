import {ReactNode} from 'react';
import {Combobox} from '@headlessui/react';

interface OptionsProps {
    children: ReactNode;
	className?: string;
}
export default function Options({ children, className, ...props }: OptionsProps) {

	return (
		<Combobox.Options as={'ul'} className={'z-30 absolute mt-1 bg-neutral-90 w-full border border-neutral-70 divide-y divide-neutral-70 rounded ' + className} {...props}>
			{children}
		</Combobox.Options>
	);
}