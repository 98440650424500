import {ReactNode} from 'react';
import { Tab } from './components';

interface CategoryTabsProps {
  children: ReactNode;
}

export default function CategoryTabs({children}: CategoryTabsProps) {
	return (
		<div className='flex space-x-5'>
			{children}
		</div>
	);
}

CategoryTabs.Tab = Tab;