export default function CreoviaLoad(props : {className?: string}) {
	return (
		<div
			className={'absolute top-0 left-0 w-full h-full bg-neutral-95 bg-opacity-50 z-40 flex items-center justify-center'}>
			<svg className={`svg-container ${props.className}`} height="100" width="100" viewBox="0 0 50 50">
				<path className={'path-track'} d="M41.1,21.3C39.8,13,32.7,6.7,24,6.7C15.3,6.7,8.2,13,6.9,21.3h5.5C13.6,16,18.3,12,24,12c6.6,0,12,5.4,12,12
		s-5.4,12-12,12c-5.7,0-10.5-4-11.7-9.4H6.9c1.3,8.3,8.4,14.8,17.1,14.8c8.7,0,15.8-6.4,17.1-14.7h5.4c-1.3,11.3-10.9,20-22.5,20
		C11.5,46.7,1.3,36.5,1.3,24C1.3,11.5,11.5,1.3,24,1.3c11.6,0,21.2,8.7,22.5,20H41.1z"/>
				<path className={'path stroke'} d="M41.1,21.3C39.8,13,32.7,6.7,24,6.7C15.3,6.7,8.2,13,6.9,21.3h5.5C13.6,16,18.3,12,24,12c6.6,0,12,5.4,12,12
		s-5.4,12-12,12c-5.7,0-10.5-4-11.7-9.4H6.9c1.3,8.3,8.4,14.8,17.1,14.8c8.7,0,15.8-6.4,17.1-14.7h5.4c-1.3,11.3-10.9,20-22.5,20
		C11.5,46.7,1.3,36.5,1.3,24C1.3,11.5,11.5,1.3,24,1.3c11.6,0,21.2,8.7,22.5,20H41.1z"/>
			</svg>
		</div>
	);
}