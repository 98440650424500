import {
	useMutation as useTanstackMutation, UseMutationOptions, UseMutationResult,
} from '@tanstack/react-query';

import { ConnectError, isConnectError } from 'errors';

export function useMutation<
  TData = unknown,
  TError = ConnectError,
  TVariables = void,
  TContext = unknown
>(
	options: UseMutationOptions<TData, TError, TVariables, TContext>,
): UseMutationResult<TData, TError, TVariables, TContext> {
	const result = useTanstackMutation<TData, TError, TVariables, TContext>(options);
	const { error } = result;

	let connectError: ConnectError | undefined;
	if (error) {
		if (!isConnectError(error))
			connectError = new ConnectError('UseMutation', 'An error occurred while fetching data.', 'Unknown error.');
		else
			connectError = error;
	}

	return {
		...result,
		error: connectError,
	} as UseMutationResult<TData, TError, TVariables, TContext>;
}