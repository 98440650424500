type ErrorName = 'InternalError' | 'AuthenticationError' | 'InvalidRefreshTokenError' | 'RequestError' | 'UseQuery' | 'UseMutation';
export class ConnectError extends Error {
	name: ErrorName;
	message: string;
	cause: string;
	errors: Array<string>;

	constructor(name: ConnectError['name'], message: ConnectError['message'], cause: ConnectError['cause'], errors?: ConnectError['errors']) {
		super();
		this.name = name;
		this.message = message || 'Something went wrong.';
		this.cause = cause;
		this.errors = errors || [];
	}
}

export function isConnectError(error: unknown): error is ConnectError {
	return (
		typeof error === 'object' && error !== null
		&& 'name' in error
		&& 'message' in error
		&& 'cause' in error
		&& 'errors' in error
	);
}

export interface ConnectAPIError {
	response: {
		data: {
			errors?: Array<string>;
		} & { detail: string } | { message: string }
	}
}

export function isConnectAPIError(error: unknown): error is ConnectAPIError {
	return (
		typeof error === 'object' && error !== null
		&& 'response' in error
		&& typeof error.response === 'object'
		&& error.response !== null
		&& 'data' in error.response
		&& typeof error.response.data === 'object'
		&& error.response.data !== null
		&& (
			'message' in error.response.data ||
			'detail' in error.response.data
		)
	);
}