import { createSlice } from '@reduxjs/toolkit';

export interface MagnifyState {
	dashboard: string | null,
}

export const initialMagnifyState = (): MagnifyState => {
	return {
		dashboard: null,
	};
};

export const magnifySlice = createSlice({
	name: 'magnify',
	initialState: initialMagnifyState(),
	reducers: {
		setDashboard: (state, action: { payload: string }) => {
			state.dashboard = action.payload;
		},
		clearDashboard: (state) => {
			state.dashboard = null;
		}
	}
});
export const { setDashboard, clearDashboard } = magnifySlice.actions;
export default magnifySlice.reducer;