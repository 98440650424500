import { ReactNode } from 'react';

interface TopbarProps {
  children: ReactNode
}
export default function Topbar({children}: TopbarProps) {
	return (
		<div className='flex justify-between w-full'>
			{children}
		</div>
	);
}