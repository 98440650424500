import {Combobox} from '@headlessui/react';
import {ReactNode, useRef, useState} from 'react';
interface ButtonProps<T> extends React.HTMLAttributes<HTMLInputElement>{
	className?: string;
	value: string;
	displayValue?: (selected: T) => string;
	children: ReactNode;
	setValue: (value: string) => void;
}
export default function Input<T>({ className, children, setValue,  ...props}: ButtonProps<T>) {
	const inputRef = useRef<HTMLInputElement>(null);
	function RedirectFocusToInput() {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}

	return (
		<Combobox.Input as={'div'} onClick={RedirectFocusToInput} className={'cursor-text relative w-full min-h-10 rounded flex py-2 bg-neutral-90 border border-neutral-70 px-2 text-left disabled:opacity-50 disabled:cursor-not-allowed ' + className}>
			<div className={'flex gap-1 flex-wrap'}>
				{children}
				<input ref={inputRef} className={'bg-neutral-90 outline-none min-w-20'} onChange={(e) => setValue(e.target.value)} {...props}/>
			</div>
		</Combobox.Input>
	);
}