import {ChangeEvent, useState} from 'react';

export function useForm<T>(inputs: T) {
	const [formState, setFormState] = useState({ ...inputs });

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = e.target;

		setFormState((prevState) => {
			return {
				...prevState,
				[name]: type === 'checkbox' ? checked : value
			};
		});
	};

	return { formState, handleChange};
}

export default useForm;