import React from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { DebouncedInput } from '../../../../../../utils';
import { Column } from '@tanstack/react-table';
import { StringFilters, RangeFilters, ListFilters } from './components';
import DateFilters from './components/DateFilters';

interface UniqueValuesListProps<T> {
    uniqueValues: Map<any, number>;
    column: Column<T>;
}
export default function UniqueValuesList<T>({ uniqueValues, column }: UniqueValuesListProps<T>) {
    function arraysEqual(arr1: any[], arr2: any[]) {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
                if (!arraysEqual(arr1[i], arr2[i])) {
                    return false;
                }
            } else if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }

    function includesNestedArray(mainArray: any[], subArray: any[]) {
        for (let i = 0; i < mainArray.length; i++) {
            if (Array.isArray(mainArray[i]) && arraysEqual(mainArray[i], subArray)) {
                return true;
            }
        }
        return false;
    }

    function isFilterApplied(value: string | string[]) {
        const appliedFilters = column?.getFilterValue() ? [...(column?.getFilterValue() as Array<string>)] : [];
        if (appliedFilters.length < 1) return false;
        if (Array.isArray(value)) return includesNestedArray(appliedFilters, value);
        return appliedFilters.includes(value);
    }

    function handleFilterClick(value: string) {
        const appliedFilters = column.getFilterValue() ? [...(column.getFilterValue() as Array<string>)] : [];
        if (isFilterApplied(value)) {
            column.setFilterValue(appliedFilters.filter((filter) => filter !== value));
            return;
        }
        column.setFilterValue([...appliedFilters, value]);
    }

    return (
        <div className={'h-[380px] border-neutral-70 border-l rounded rounded-l-none mt-3 bg-neutral-70 w-52 sm:w-64 relative'}>
            {column.columnDef.meta?.data_type === 'list' ? (
                <ListFilters handleFilterClick={handleFilterClick} isFilterApplied={isFilterApplied} uniqueValues={uniqueValues} column={column} />
            ) : column.columnDef.meta?.data_type === 'str' ? (
                <StringFilters handleFilterClick={handleFilterClick} isFilterApplied={isFilterApplied} uniqueValues={uniqueValues} column={column} />
            ) : column.columnDef.meta?.data_type === 'int' ||
              column.columnDef.meta?.data_type === 'float' ||
              column.columnDef.meta?.data_type === 'num' ||
              column.columnDef.meta?.data_type === 'range' ? (
                <RangeFilters column={column} />
            ) : column.columnDef.meta?.data_type === 'date' ? (
                <DateFilters column={column} />
            ) : null}
        </div>
    );
}
