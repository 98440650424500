import React from 'react';

interface DirectoryIconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  titleId?: string;
}

function DirectoryIcon({
  title,
  titleId,
  ...props
}: DirectoryIconProps, svgRef: React.LegacyRef<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" fill="currentColor">
      <path d="M267.5,592.9c51.2,0,106.2-68.1,106.2-138c0-69.9-47.6-115.3-106.2-115.3c-58.7,0-106.2,45.3-106.2,115.3
        C161.2,524.8,214.5,592.9,267.5,592.9z" />
      <path d="M932.5,592.9c51.2,0,106.2-68.1,106.2-138c0-69.9-47.6-115.3-106.2-115.3s-106.2,45.3-106.2,115.3
        C826.3,524.8,879.6,592.9,932.5,592.9z"/>
      <path d="M601.8,619.8c78.6,0,162.9-104.4,162.9-211.7c0-107.3-72.9-176.8-162.9-176.8c-90,0-162.9,69.5-162.9,176.8
        C438.9,515.3,520.7,619.8,601.8,619.8z" />
      <path d="M893.3,802.4c-19.7-39-34-58.7-60.8-86.5c-23.1-24-92.1-57.9-136.6-43c-35.5,7.8-37.2,28.2-94.1,28.2
        c-56.9,0-58.6-20.4-94.1-28.2c-44.5-14.9-113.5,19-136.6,43c-26.7,27.8-41.1,47.5-60.8,86.5c-9.9,19.6-30.5,76.7-17.7,101.2
        c16.2,31.1,55.5,43.9,163.6,57.2c64.7,7.9,145.6,7.9,145.6,7.9s80.8,0,145.6-7.9c108.1-13.2,147.4-26.1,163.6-57.2
        C923.7,879,903.1,821.9,893.3,802.4L893.3,802.4z" />
      <path d="M1122.6,712c-12.8-25.5-22.2-38.3-39.6-56.4c-15.1-15.7-60-37.7-89.1-28c-23.2,5.1-24.3,18.4-61.3,18.4
        c-37.1,0-38.2-13.3-61.3-18.4c-23.3-7.8-56.9,4.9-77.1,18.4c27.7,11.4,52.3,27.3,67,42.5c30.4,31.6,46.9,55,67.6,96.2
        c4,8,9.4,20.9,14.3,35.8c17.4-0.3,53.7-1.3,84.4-5.1c70.5-8.6,96.1-17,106.7-37.3C1142.5,761.9,1129.1,724.7,1122.6,712L1122.6,712
        z" />
      <path d="M274.9,784.5c20.8-41.2,37.2-64.5,67.6-96.2c14.3-14.8,37.9-30.2,64.7-41.5c-19.9-13.8-54.6-27.3-78.4-19.3
        c-23.2,5.1-24.3,18.4-61.3,18.4c-37.1,0-38.2-13.3-61.3-18.4c-29-9.7-74,12.4-89.1,28c-17.4,18.1-26.8,30.9-39.6,56.4
        c-6.4,12.8-19.8,50-11.5,66c10.5,20.3,36.2,28.7,106.6,37.3c33,4,72.2,4.9,87.9,5.1C267,800.4,274.2,785.9,274.9,784.5L274.9,784.5
        z" />
    </svg>
  )
}

const ForwardRef = React.forwardRef(DirectoryIcon);
export default ForwardRef;