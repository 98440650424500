import { Tokens, User } from '@types';
import callAPI from 'services';
import { authEndpoints, userEndpoints } from 'services/api';
import { LoginCredentialsPayload, PasswordChangePayload } from '@modules/auth/schemas';
import { z } from 'zod';

export async function login(credentials: LoginCredentialsPayload): Promise<Tokens> {

	return await callAPI<Tokens>(authEndpoints.login, {
		method: 'POST',
		data: credentials
	});
}

export async function updatePassword(updatedPassword: PasswordChangePayload) {

	return await callAPI<User>(authEndpoints.updatePassword, {
		method: 'POST',
		data: updatedPassword
	});
}

export async function requestPasswordReset(email: string) {
	z.string().email().parse(email);

	return await callAPI(userEndpoints.requestPasswordReset, {
		method: 'POST',
		data: { email }
	});
}

export async function fetchResetPasswordToken(reset_code: string) {

	return await callAPI<{ access: string, refresh: string }>(userEndpoints.passwordResetToken, {
		method: 'POST',
		data: { reset_code: reset_code },
	});
}

export async function resetPassword(payload: PasswordChangePayload & { reset_code: string, reset_token: string }) {

	return await callAPI(userEndpoints.resetPassword, {
		method: 'POST',
		data: payload
	});
}

export async function requestRegistrationEmail(email: string, theme = 'light') {
	z.string().email().parse(email);

	return await callAPI(userEndpoints.register(email, theme));
}

export async function createAccount(code: string, payload: { password: string, password_confirm: string, firstName: string, lastName: string, phoneNumber: string }) {

	return await callAPI(userEndpoints.confirmRegistration(code), {
		method: 'POST',
		data: {
			first_name: payload.firstName,
			last_name: payload.lastName,
			phone_number: payload.phoneNumber,
			password: payload.password,
			password_confirm: payload.password_confirm,
		}
	});
}