import React, { useState, useRef, useEffect, Fragment } from 'react';
import datefns, { format, getDate, getMonth, getYear, isDate } from 'date-fns';
import Calendar from './components/Calendar';
import RangeDateInput from './components/RangeDateInput';
import { Popover } from '@headlessui/react';
import { CalendarIcon } from '@heroicons/react/16/solid';
import { input } from 'zod';

interface Props {
    label?: string;
    id?: string;
    time?: boolean;
    date?: Date;
    disabled?: boolean;
    dateRange?: [string, string];
    timeRange?: [string, string];
    range?: boolean;
    futureOnly?: boolean;
    pastOnly?: boolean;
    onChange?: (arg0: string | { start: string; end: string }) => void;
}

const isLeapYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const getDaysInMonth = (month: number, year: number) => {
    return new Date(year, month, 0).getDate();
};

export default function DateInput({ ...props }: Props) {
    const [month, setMonth] = useState(props.date ? (getMonth(props.date) + 1).toString() : '');
    const [day, setDay] = useState(props.date ? getDate(props.date).toString() : '');
    const [year, setYear] = useState(props.date ? getYear(props.date).toString() : '');
    const [date, setDate] = useState<string>(props.date ? format(props.date, 'MM/dd/yyyy') : '');
    const [selectedRange, setSelectedRange] = useState<{ start: string; end: string }>({ start: '', end: '' });

    const dayInputRef = useRef<HTMLInputElement | null>(null);
    const yearInputRef = useRef<HTMLInputElement | null>(null);

    const handleCalendarChange = (date: string | { start: string; end: string }) => {
        if (props.range) setSelectedRange(date as { start: string; end: string });
        else if (typeof date === 'string' && date !== '') {
            setDate(format(date as string, 'MM/dd/yyyy'));
            if (props.date && props.onChange) props.onChange(date);
            setYear(format(date as string, 'yyyy'));
            setMonth(format(date as string, 'MM'));
            setDay(format(date as string, 'dd'));
        }
    };

    const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        let value = '';
        if (typeof e === 'object' && 'target' in e) {
            value = (e.target as HTMLInputElement).value;
        }
        if (value.length <= 2 && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 12))) {
            setMonth(value);
            if (value.length === 2 && dayInputRef.current) {
                dayInputRef.current.focus();
            }
        }
    };

    const handleDayChange = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        let value = '';
        if (typeof e === 'object' && 'target' in e) {
            value = (e.target as HTMLInputElement).value;
        }
        const maxDays = getDaysInMonth(parseInt(month.padStart(2, '0'), 10) || 1, parseInt(year, 10) || new Date().getFullYear());
        if (value.length <= 2 && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= maxDays))) {
            setDay(value);
            if (value.length === 2 && yearInputRef.current) {
                yearInputRef.current.focus();
            }
        }
    };

    const handleDayKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key && e.key !== 'Backspace') {
            handleDayChange(e);
        }
    };

    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, '');
        if (value.length <= 4) {
            setYear(value);
        }
    };

    useEffect(() => {
        if (month.length > 0 && day.length > 0 && year.length === 4) {
            const enteredDate = new Date(Number(year), Number(month) - 1, Number(day));
            if (isDate(enteredDate) && format(enteredDate, 'MM/dd/yyyy') !== date) {
                setDate(format(enteredDate, 'MM/dd/yyyy'));
                if (props.date && props.onChange) props.onChange(enteredDate.toISOString());
            }
        }
    }, [month, day, year]);

    return (
        <div className={'space-y-10'}>
            <div className={'p-1 pl-3 bg-neutral-95 rounded-xl border border-neutral-80 w-fit flex text-neutral-30 items-center relative'}>
                {/*<label htmlFor={props.id}>{props.label}</label>*/}
                {/* smells below */}
                {props.range ? (
                    <RangeDateInput onChange={() => undefined} futureOnly={props.futureOnly} pastOnly={props.pastOnly} />
                ) : (
                    <>
                        <input
                            disabled={props.disabled}
                            type="text"
                            placeholder="mm"
                            value={month}
                            onChange={handleMonthChange}
                            onKeyUp={handleMonthChange}
                            maxLength={2}
                            className="bg-neutral-95 w-8"
                        />
                        <span className={'mr-1'}>/</span>
                        <input
                            disabled={props.disabled}
                            type="text"
                            placeholder="dd"
                            value={day}
                            onChange={handleDayChange}
                            onKeyUp={handleDayKeyUp}
                            maxLength={2}
                            className="bg-neutral-95 w-7"
                            ref={dayInputRef}
                        />
                        <span className={'mr-1'}>/</span>
                        <input
                            disabled={props.disabled}
                            type="text"
                            placeholder="yyyy"
                            value={year}
                            onChange={handleYearChange}
                            maxLength={4}
                            className="bg-neutral-95 w-10"
                            ref={yearInputRef}
                        />

                        {/*{props.time && <input type="time" id={props.id} name={props.id}/>}*/}
                        <Popover as={'div'} className="relative">
                            <Popover.Button disabled={props.disabled} className={'h-8 p-1 aspect-square flex'}>
                                <CalendarIcon />
                            </Popover.Button>
                            <Popover.Panel className={'absolute right-0 mt-2 bg-neutral-90 z-50'}>
                                <Calendar
                                    date={date}
                                    onChange={handleCalendarChange}
                                    futureOnly={props.futureOnly}
                                    pastOnly={props.pastOnly}
                                    range={props.range}
                                />
                            </Popover.Panel>
                        </Popover>
                    </>
                )}
            </div>
        </div>
    );
}
