import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

export default function useSearchQuery<TData extends Array<TDatum>, TDatum>(
	data: TData,
	query: string,
	options?: {
		fields?: Array<TDatum extends object ? keyof TDatum & string : string>;
		delay?: number;
	}
): TData {
	const [filteredData, setFilteredData] = useState<TData>(data);
	const { fields, delay = 300 } = options || {};

	function filterDataByQuery() {
		const queryLowerCase = query.toLowerCase();

		const d = data.filter((datum) => {
			if (fields === undefined) {
				return searchValue(datum, queryLowerCase);
			}

			return fields.some((field) => searchValue(getNestedValue(datum, field), queryLowerCase));
		}) as TData;

		setFilteredData(d);
	}

	const debouncedFilterDataByQuery = useMemo(
		() => debounce(filterDataByQuery, delay),
		[data, fields, query, delay]
	);

	useEffect(() => {
		delay > 0 ? debouncedFilterDataByQuery() : filterDataByQuery();

		return () => {
			debouncedFilterDataByQuery.cancel();
		};
	}, [debouncedFilterDataByQuery]);

	function searchValue(value: unknown, query: string): boolean {
		if (typeof value === 'string' || typeof value === 'number') {
			return value.toString().toLowerCase().includes(query);
		} else if (Array.isArray(value)) {
			return value.some((v) => searchValue(v, query));
		} else if (typeof value === 'object' && value !== null) {
			const objectValues = Object.values(value);
			const objectKeys = Object.keys(value);
			return (
				objectValues.some((v) => searchValue(v, query)) ||
      objectKeys.some((k) => searchValue(k, query))
			);
		}
		return false;
	}

	function getNestedValue(obj: TDatum, path: string): unknown {
		const keys = path.split('.');
		let currentObj = obj;

		for (const key of keys) {
			if (typeof currentObj === 'object' && currentObj !== null && key in currentObj) {
				currentObj = (currentObj as any)[key];
			} else {
				return undefined;
			}
		}

		return currentObj;
	}

	return filteredData;
}