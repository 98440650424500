// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useEffect, useRef} from 'react';
import { useRangeCalendarState } from '@react-stately/calendar';
import { useLocale } from '@react-aria/i18n';
import { createCalendar } from '@internationalized/date';
import { CalendarGrid, CalendarHeader } from './components';
import { CalendarDate } from '@internationalized/date';
import { useRangeCalendar } from '@react-aria/calendar';

const options = {
	year: 'numeric',
	month: 'short',
	day: 'numeric',
};

export default function RangeCalendar({ updateRange, ...props }: any) {
	const { locale } = useLocale();

	const state = useRangeCalendarState({
		...props,
		locale,
		createCalendar
	});

	const { value } = state;

	const handleClearRange = () => {
		state.setValue(null);
		updateRange({start: null, end: null});
	};

	const rangeToString = () => {
		function dateToString(date) {
			return new Intl.DateTimeFormat(locale, options).format(date.toDate(Intl.DateTimeFormat().resolvedOptions().timeZone));
		}

		const start = value.start ? dateToString(value.start) : '';
		const end = value.end ? dateToString(value.end) : '';
		return `${start !== end ? `${start} - ${end}` : start}`;
	};

	useEffect(() => {
		if (!value) return;
		const {start, end} = value;
		const startDate = new CalendarDate(start.year, start.month, start.day);
		const endDate = new CalendarDate(end.year, end.month, end.day);

		updateRange({start: startDate, end: endDate});

	}, [value]);


	const ref = useRef();
	const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
		props,
		state,
		ref
	);

	return (
		<>
			<div {...calendarProps} ref={ref} className="inline-block">
				<CalendarHeader
					state={state}
					calendarProps={calendarProps}
					prevButtonProps={prevButtonProps}
					nextButtonProps={nextButtonProps}
				/>
				<div className="flex gap-2">
					<CalendarGrid state={state} />
				</div>
			</div>

			<div className=" w-full inline-flex justify-around items-center p-2 text-s text-neutral-40 h-fit">
				<div className="w-full">
					<span>{value ? rangeToString() : 'No date selected.'}</span>
				</div>
				<div>
					<button
						className="bg-primary-40 rounded-md p-2 text-neutral-90"
						onClick={handleClearRange}>
                        Clear
					</button>
				</div>

			</div>
		</>
	);
}
