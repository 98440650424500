import { DirectoryGroup } from '@modules/directory/types/Directory';
import { GroupGridItem } from './components';
import { GroupGridItem as GridItemType, AppGroup } from '@types';

interface Props {
	groups: Array<AppGroup>;
	onGroupClick: (group: AppGroup) => void;
}

export default function GroupGrid({ groups, onGroupClick }: Props) {

	const groupByAlphabet = groups.reduce((result, appGroup) => {
		const letter = appGroup.group.name[0].match(/\d/) ? '#' : appGroup.group.name[0].toUpperCase();
		result[letter] = result[letter] || [];
		result[letter].push(appGroup);

		return result;
	}, {} as Record<string, Array<AppGroup>>);


	return (
		<ul>
			{Object.entries(groupByAlphabet).sort().map(([letter, groupedGroups]) => (
				<div key={letter} className="relative flex flex-col gap-4 text-[#1E3A8A] text-center" >
					<h2 className='w-8 border-b-2 border-[#2563EB] mt-2 text-lg'>{letter}</h2>
					<div className='grid grid-cols-1 @sm:grid-cols-2 @md:grid-cols-3 @lg:grid-cols-4 @xl:grid-cols-5 gap-5'>
						{groupedGroups.map((group) => (
							<GroupGridItem onGroupClick={onGroupClick} key={group.group.id} group={group} />
						))}
					</div>
				</div>
			))}
		</ul>
	);
}