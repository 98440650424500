import {Column} from '@tanstack/react-table';
import RangeCalendar from '../../../../../../../../../RangeCalendar';
import {useEffect, useState} from 'react';
import {useLocale} from '@react-aria/i18n';

interface DateFiltersProps<T> {
  column: Column<T>;
}

type DateFilter = {
  start: string | null;
  end: string | null;
}

export default function DateFilters<T>({column}: DateFiltersProps<T>) {
	const [range, setRange] = useState<DateFilter | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const locale = useLocale();

	useEffect(() => {
		let startValue = null;
		let endValue = null;
		setIsLoading(true);
		if (column.getIsFiltered()) {
			const filterValue = column.getFilterValue() as string[];
			if (filterValue.length > 0) {
				startValue = filterValue[0];
				endValue = filterValue[1];
			}
		}
		setRange({start: startValue, end: endValue});
	}, [column]);

	function handleDateFilterChange(range: { start: string | null, end: string | null } | null) {
		if (!range?.start || !range?.end) {
			column.setFilterValue([]);
			return;
		}
		column.setFilterValue([range.start, range.end]);
	}

	useEffect(() => {
		if (range) setIsLoading(false);
	}, [range]);

	if (isLoading) return null;

	return (
		<div className={'w-full flex flex-col p-2 justify-between h-full'}>
			<RangeCalendar defaultValue={range?.start && range?.end ? {...range} : null} updateRange={handleDateFilterChange}/>
		</div>
	);
}