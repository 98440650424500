import {XMarkIcon} from '@heroicons/react/20/solid';
import { CartItem as CartItemType } from '@modules/pay/types';
import { useCallback, useState } from 'react';
import {useRemoveCartItem, useUpdateCartItem} from '@modules/pay/hooks';
import { debounce } from 'lodash';

export default function CartItem({ item }: {item: CartItemType}) {
	const [quantity, setQuantity] = useState(item.quantity);

	const { mutate: updateCartItem } = useUpdateCartItem(item.id, quantity);

	const { mutate: removeCartItem } = useRemoveCartItem(item.id);

	const debouncedUpdateCartItem = useCallback(debounce(() => updateCartItem(), 500), [updateCartItem]);

	function handleQuantityChange(event: React.ChangeEvent<HTMLInputElement>) {
		setQuantity(Number(event.target.value));
		debouncedUpdateCartItem();
	}

	function handleRemoveCartItem() {
		removeCartItem();
	}

	return (
		<div key={item.id} className={'bg-neutral-90 border border-neutral-70  overflow-clip flex items-center justify-between'}>
			<div className='flex'>
				<div className={'bg-neutral-90 border-r border-neutral-70 w-24 '}></div>
				<ul className={'text-sm p-2 space-y-2'}>
					<li className={'text-base'}>{item.name}</li>
					<li className={'bg-neutral-90 w-fit p-1 px-2 rounded'}>{item.variation.name}</li>
					{item.selected_choices.map((selectedChoice) => (
						<li key={selectedChoice.id} className={'bg-neutral-90 w-fit p-1 px-2 rounded'}>{selectedChoice.option}: {selectedChoice.choice}</li>
					))}
					<label htmlFor={'qty'}>QTY: </label>
					<input min={1} id={'qty'} type={'number'} className={'w-10 pl-1 rounded'} value={quantity} onChange={handleQuantityChange}/>
				</ul>
			</div>

			<div className='items-center flex p-2 gap-2'>
				<div>
					<h3 className={'self-end'}>${(item.total_price / 100).toFixed(2)}</h3>
				</div>
				<button onClick={() => handleRemoveCartItem()} className={'p-1 bg-error-50/30 text-error-40 rounded aspect-square'}>
					<XMarkIcon className='h-6 w-6'/>
				</button>
			</div>

		</div>
	);
}