import React, { Fragment, useState } from 'react';
import { Popover } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/16/solid";
import Calendar from "./Calendar";
import { format } from "date-fns";

interface Props {
    dateRange?: [string, string];
    timeRange?: [string, string];
    futureOnly?: boolean
    pastOnly?: boolean
    onChange?: (arg0: { start: string, end: string }) => void;
}

export default function RageDateInput(props: Props) {
    const { onChange } = props

    const [startMonth, setStartMonth] = useState("");
    const [startDay, setStartDay] = useState("");
    const [startYear, setStartYear] = useState("");

    const [endMonth, setEndMonth] = useState("");
    const [endDay, setEndDay] = useState("");
    const [endYear, setEndYear] = useState("");

    const [range, setRange] = useState();

    function handleCalendarChange(range: { start: string, end: string } | string) {
        if (typeof range === 'string') return
        if (range.start) {
            setStartMonth(format(range.start, 'MM'))
            setStartDay(format(range.start, 'dd'))
            setStartYear(format(range.start, 'yyyy'))
        } else {
            setStartMonth("")
            setStartDay("")
            setStartYear("")
        }

        if (range.end) {
            setEndMonth(format(range.end, 'MM'))
            setEndDay(format(range.end, 'dd'))
            setEndYear(format(range.end, 'yyyy'))
        } else {
            setEndMonth("")
            setEndDay("")
            setEndYear("")
        }
    }

    return (
        <>
            <input
                type="text"
                placeholder="mm"
                maxLength={2}
                className="bg-neutral-95 w-8"
                value={startMonth}
            />
            <span className={'mr-1'}>/</span>
            <input
                type="text"
                placeholder="dd"
                maxLength={2}
                className="bg-neutral-95 w-7"
                value={startDay}
            />
            <span className={'mr-1'}>/</span>
            <input
                type="text"
                placeholder="yyyy"
                maxLength={4}
                className="bg-neutral-95 w-10"
                value={startYear}
            />
            <span className={'mr-2'}>—</span>
            <input
                type="text"
                placeholder="mm"
                maxLength={2}
                className="bg-neutral-95 w-8"
                value={endMonth}
            />
            <span className={'mr-1'}>/</span>
            <input
                type="text"
                placeholder="dd"
                maxLength={2}
                className="bg-neutral-95 w-7"
                value={endDay}
            />
            <span className={'mr-1'}>/</span>
            <input
                type="text"
                placeholder="yyyy"
                maxLength={4}
                className="bg-neutral-95 w-10"
                value={endYear}
            />
            <Popover as={Fragment}>
                <Popover.Button className={'h-6 aspect-square'}>
                    <CalendarIcon />
                </Popover.Button>
                <Popover.Panel className={'absolute translate-y-1/2 -left-[1px] mt-10 '}>
                    <Calendar range {...props} onChange={handleCalendarChange} />
                </Popover.Panel>
            </Popover>
        </>
    )
}