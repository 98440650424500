import {ComponentPropsWithoutRef, PropsWithChildren, ReactNode} from 'react';
import { Listbox } from '@headlessui/react';

interface OptionProps<T> extends PropsWithChildren<ComponentPropsWithoutRef<typeof Listbox>> {
	children: ReactNode;
    disabled?: boolean;
    value: T;
}

export default function Option<T>({ children, ...props }: OptionProps<T>) {
	return (
		<Listbox.Option className={'p-2 cursor-pointer hover:bg-neutral-70 '} {...props}>
			{children}
		</Listbox.Option>
	);
}