import {ReactNode} from 'react';
import {Combobox} from '@headlessui/react';
interface OptionProps<T> {
    children: ReactNode;
    value: T;
}
export default function Option<T>({value, children}: OptionProps<T>) {
	return (
		<Combobox.Option value={value} className={({active}) => `p-2
		${active ? 'bg-primary-80' : ''}`}>
			{children}
		</Combobox.Option>
	);
}