import { themes, textSize, iconSize} from './styles';

type ButtonPropsType = {
	children: React.ReactNode,
	className?: string,
	disabled?: boolean,
	options?: object,
	onClick: (e?: React.MouseEvent<HTMLElement>) => void
}

export default function Button({ children, className, disabled, onClick, options }: ButtonPropsType) {

	if (!children) throw new Error('no children were passed into Button component');

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault(); // Call preventDefault here
		if (onClick) {
			onClick(e); // Then call the original onClick function with the event
		}
	};

	const config = {
		theme: 'primary',
		disabled: false,
		size: 'base',
		...options
	};

	const hasMultipleChildren = Array.isArray(children);

	const styles = themes[config.theme as 'primary'][config.disabled ? 'disabled' : 'enabled'];

	return (
		<button
			className={styles + ' w-fit flex items-center gap-1 ease-in-out duration-300 font-medium ' + textSize[config.size as 'base'] + ' ' + className}
			onClick={handleClick}>
			{
				hasMultipleChildren ?
					children.map((child, i) => {
						if (typeof child !== 'string') return (<div key={i} className={' aspect-square ' + iconSize[config.size as 'base']}>{child}</div>);
						return (
							<div key={i}>{child}</div>
						);
					})
					:
					typeof children === 'string' ?
						<div className={textSize[config.size as 'base']}>{children}</div>
						:
						<div className={ ' aspect-square ' + iconSize[config.size as 'base'] }>{children}</div>
			}
		</button>
	);
}