import { ColumnType } from '../Types';
import { ColumnDef, FilterFn, Row, RowData, Table } from '@tanstack/react-table';
import React from 'react';

import '@tanstack/react-table'; //or vue, svelte, solid, etc.
import { format } from 'date-fns';

export default function mutateColumns<T>(columns: ColumnType<T>[], getTable: () => Table<T>): ColumnDef<T>[] {
	const getSortingFn = (rowA: Row<T>, rowB: Row<T>, columnId: string, column: ColumnType<T>) => {
		// const column = table.getColumn(columnId)?.columnDef?.meta?.original;
		// if (!column) return 0;
		if (column.data_type === 'num' || column.data_type === 'int' || column.data_type === 'float' || column.data_type === 'range' || column.key === 'Risk_Factor') {
			const a = Number(rowA.getValue(columnId));
			const b = Number(rowB.getValue(columnId));
			return a < b ? -1 : a > b ? 1 : 0;
		} else if (column.key === 'Letter_Grade') {
			const grade = ['F', 'D-', 'D', 'D+', 'P', 'C-', 'C', 'C+', 'B-', 'B', 'B+', 'A-', 'A', 'A+'];
			const a = grade.indexOf(rowA.getValue(columnId) as string);
			const b = grade.indexOf(rowB.getValue(columnId) as string);
			return a < b ? -1 : a > b ? 1 : 0;
		} else if (column.key === 'Grade' || column.key === 'Grade_Level') {
			const grade = ['KG', 'K', '01', '1', '02', '2', '03', '3', '04', '4', '05', '5', '06', '6', '07', '7', '08', '8', '09', '9', '10', '11', '12'];
			const a = grade.indexOf(rowA.getValue(columnId) as string);
			const b = grade.indexOf(rowB.getValue(columnId) as string);
			return a < b ? -1 : a > b ? 1 : 0;
		} else if (column.data_type === 'date') {
			const a = new Date(rowA.getValue(columnId) as string).getTime();
			const b = new Date(rowB.getValue(columnId) as string).getTime();
			return a < b ? -1 : a > b ? 1 : 0;
		} else if (column.data_type === 'bool') {
			const a = rowA.getValue(columnId) as boolean;
			const b = rowB.getValue(columnId) as boolean;
			return a === b ? 0 : a ? 1 : -1;
		} else if (column.data_type === 'str') {
			const a = rowA.getValue(columnId) as string;
			const b = rowB.getValue(columnId) as string;
			if (a === null) return (b === null) ? 0 : -1;
			if (b === null) return 1;
			return a.localeCompare(b);
		}
		return 0;
	};

	function dateToEpoch(date: any) {
		return new Date(date).getTime();
	}
	const customFilterFn: FilterFn<T> = (row, columnId, filterValue) => {
		const table = getTable();
		const column = table.getColumn(columnId);
		const data_type = column?.columnDef?.meta?.data_type;
		if (data_type === 'num' || data_type === 'int' || data_type === 'float' || data_type === 'range') {
			const value = Number(row.getValue(columnId));
			const filter = filterValue as [number, number];
			return value >= filter[0] && value <= filter[1];
		} else if (data_type === 'date') {
			const value = new Date(row.getValue(columnId) as string).getTime();
			const filter = filterValue as [number, number];
			return value >= dateToEpoch(filter[0]) && value <= dateToEpoch(filter[1]);
		} else if (data_type === 'list') {
			const value = row.getValue(columnId)
			if (Array.isArray(value)) {
				return filterValue.some((v: string) => value.includes(v))
			}
		}
		return filterValue.includes(row.getValue(columnId));
	};

	return columns.map(column => {
		return {
			header: column.title,
			id: column.key ?? column.id,
			enableHiding: column.hide ?? false,
			accessorKey: column.key,
			accesorFn: column.accessorFn,
			enableSorting: column.canSort ?? true,
			sortingFn: (rowA, rowB, columnId) => getSortingFn(rowA, rowB, columnId, column),
			size: column.size,
			filterFn: customFilterFn,
			enablePinning: column.enablePinning ?? true,
			enableResizing: column.canResize ?? true,
			enableColumnFilter: column.is_filter,
			meta: {
				key: column.key,
				data_type: column.data_type,
				search_reference_keys: column.search_reference_keys,
			},
			cell: (item) => column.render ? column.render(item.row.original) : item.renderValue() ?? '—'
		};
	})
}