import React, { useEffect } from 'react';
import { CheckIcon } from '@icons';
import { motion, AnimatePresence } from 'framer-motion';
import { CreoviaLoad, TitledList } from '@modules/common/components';
import { AtSymbolIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { ClipboardIcon } from '@heroicons/react/24/outline'
import { ArrowUpRightIcon } from '@heroicons/react/16/solid';
import { ProfileCard } from '@modules/directory/types';
import { useSensitiveProfileInfo } from '@modules/directory/hooks';

type DirectoryPinnedCardProps = {
	person: ProfileCard;
}
export default function DirectoryPinnedCard(props: DirectoryPinnedCardProps) {
	const [copiedTooltip, setCopiedTooltip] = React.useState<string | null>(null);
	const { person } = props;

	const { data: sensitiveData, isLoading } = useSensitiveProfileInfo(person.id)

	useEffect(() => {
		if (!copiedTooltip) return;
		setTimeout(() => {
			setCopiedTooltip(null);
		}, 3000);
	}, [copiedTooltip]);

	const handleCopyClick = (e: any, datum: any) => {
		navigator.clipboard.writeText(datum);
		setCopiedTooltip(datum);
	};

	return (
		<div className='p-3 flex flex-col gap-2'>
			<div className='p-5 group/banner bg-neutral-95 rounded-xl relative'>
				<div style={{
					backgroundImage: `url('${person.organization.img_url}')`
				}}
					className=' h-10 bg-no-repeat bg-center bg-contain rounded-xl bg-neutral-95'
				>
				</div>

			</div>
			<div style={{
				backgroundImage: `url('${person.img_url}'), url('/images/user_gray.jpg')`,
			}} className={'flex justify-between items-end w-full aspect-[7/8] overflow-clip rounded-xl bg-cover bg-center relative'}>

				<div className=" bg-slate-800 w-full bg-opacity-30 p-2 backdrop-blur-lg text-white flex justify-between items-center">
					<div>
						<h3 className={'font-semibold text-lg font-poppins drop-shadow-lg'}>{person.first_name + ' ' + person.last_name}</h3>
						<h4 className='drop-shadow-lg'>{person.title}</h4>
					</div>
					<div className={'flex  z-10 items-center'}>
						<a href={`mailto:${person.email}`} className="flex z-0 items-center p-2">
							<EnvelopeIcon className='bg-[#EFEFEF1F] aspect-square w-8 rounded  z-10 text-white cursor-pointer p-1' />
						</a>
						{sensitiveData?.phone_number
							? <a href={`tel:${sensitiveData.phone_number}`} className="flex z-0 items-center p-2">
								<PhoneIcon className='bg-[#EFEFEF1F] aspect-square w-8 rounded  z-10 text-white cursor-pointer p-1' />
							</a>
							: null}
					</div>
				</div>

			</div>

			{person.directory_location_filters && person.directory_location_filters.length > 0
				? <div>
					<h4 className='font-poppins mb-1'>Locations</h4>
					<div className='flex gap-2'>
						{person.directory_location_filters.map((location, i) => (
							<div key={i} className='p-2 bg-neutral-80 rounded-xl'>
								{location}
							</div>
						))}
					</div>
				</div>
				: null
			}
			{person.directory_department_filters && person.directory_department_filters.length > 0
				? <div>
					<h4 className='font-poppins mb-1'>Departments</h4>
					<div className='flex gap-2'>
						{person.directory_department_filters.map((location, i) => (
							<div key={i} className='p-2 bg-neutral-80 rounded-xl'>
								{location}
							</div>
						))}
					</div>
				</div>
				: null
			}


			{

				isLoading
					? <div className='bg-neutral-70 rounded-xl mt-2 overflow-clip p-2 relative h-16 w-full'><CreoviaLoad className='h-10' /></div>
					: <div className=''>

						{sensitiveData?.profile_tags && sensitiveData.profile_tags.length > 0
							? <div>
								<h4 className='font-poppins mb-1'>Tags</h4>
								<div className='flex gap-2'>
									{sensitiveData.profile_tags.map((tag, i) => {
										return (
											<div key={i} className='p-2 bg-neutral-80 rounded-xl'>
												{tag}
											</div>
										)
									})}
								</div>
							</div>
							: null
						}
						{sensitiveData?.phone_number
							? <>
								<div className={' bg-neutral-80 p-2 rounded-xl relative flex items-center justify-between mt-2'}>
									<AnimatePresence>
										{copiedTooltip === sensitiveData.phone_number ?
											<motion.div
												initial={{ opacity: 0, left: 50, top: -10 }}
												animate={{ opacity: 1, left: 50, top: -35 }}
												exit={{ opacity: 0, left: 50, top: -10 }}
												className={' absolute border-2 border-green-300 bg-neutral-90 p-2 rounded-lg shadow-lg z-50 flex gap-2 items-center'}
											>
												Copied to clipboard
												<CheckIcon className={'h-5 text-green-500'} />
											</motion.div>
											: null
										}
									</AnimatePresence>
									<div className='cursor-pointer hover:text-primary-40 flex items-center gap-1'
										onClick={(e) => {
											handleCopyClick(e, sensitiveData.phone_number);
										}}>
										{sensitiveData.phone_number}
										<ClipboardIcon className='h-5 w-5' />
									</div>
									<a href={`tel:${sensitiveData.phone_number}`}>
										<PhoneIcon className='h-5 w-5 hover:text-primary-40' />
									</a>
								</div>
							</>
							: null
						}
					</div>

			}

			{person.email
				? <>
					<div className={' bg-neutral-80 p-2 rounded-xl relative flex items-center justify-between'}>

						<AnimatePresence>
							{copiedTooltip === person.email ?
								<motion.div
									initial={{ opacity: 0, left: 50, top: -10 }}
									animate={{ opacity: 1, left: 50, top: -35 }}
									exit={{ opacity: 0, left: 50, top: -10 }}
									className={' absolute border-2 border-green-300 bg-neutral-90 p-2 rounded-lg shadow-lg z-50 flex gap-2 items-center'}
								>
									Copied to clipboard
									<CheckIcon className={'h-5 text-green-500'} />
								</motion.div>
								: null
							}
						</AnimatePresence>
						<div className='cursor-pointer hover:text-primary-40 flex items-center gap-1'
							onClick={(e) => {
								handleCopyClick(e, person.email);
							}}>
							{person.email}
							<ClipboardIcon className='h-5 w-5' />
						</div>
						<a href={`mailto:${person.email}`}>
							<AtSymbolIcon className='h-5 w-5 hover:text-primary-40' />
						</a>
					</div>
				</>

				: null
			}

		</div>
	);
}
