import React from 'react';
import { useRef } from 'react';
import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';

interface ButtonProps {
  isDisabled?: boolean;
  children: React.ReactNode;
}

export default function Button(props: ButtonProps) {
	const ref = useRef<HTMLButtonElement | null>(null);
	const { buttonProps } = useButton(props, ref);
	const { focusProps, isFocusVisible } = useFocusRing();
	return (
		<button
			{...mergeProps(buttonProps, focusProps)}
			ref={ref}
			className={`p-2 rounded-full ${props.isDisabled ? 'text-gray-400' : ''} ${
				!props.isDisabled ? 'hover:bg-primary-40 hover:text-neutral-95' : ''
			} outline-none ${
				isFocusVisible ? 'ring-2 ring-offset-2 ring-purple-600' : ''
			}`}
		>
			{props.children}
		</button>
	);
}
