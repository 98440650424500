import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changePinnedCard } from 'store/slices/pinnedCardSlice';
import { CartPinnedCard, DirectoryPinnedCard, EvaluationPinnedCard, MagnifyPinnedCard } from './components';
import { XMarkIcon } from '@icons';
import { RootState } from 'store';

export default function PinnedCards() {
	const pinnedCard: any = useSelector<RootState>(state => state.pinnedCard.currentPinnedCard);
	const dispatch = useDispatch();
	const renderCurrentCardComponent = () => {
		switch (pinnedCard.type) {
			case 'directory':
				return <DirectoryPinnedCard person={pinnedCard.data} />;

			case 'evaluation':
				return <EvaluationPinnedCard data={pinnedCard.data} />;

			case 'magnify':
				return <MagnifyPinnedCard student={pinnedCard.data} cardURL={pinnedCard.url} />;

			case 'cart':
				return <CartPinnedCard />;

			default:
				return null;
		}
	};

	return pinnedCard ? (
		<>
			<div id={'pinned-card'} className={'w-full absolute bg-neutral-95 h-full z-40 bg-opacity-60 lg:hidden '}
				onClick={() => dispatch(changePinnedCard(null))}></div>
			<div
				className={`${pinnedCard ? 'w-[28%] max-w-[500px] min-w-[400px] absolute right-0 h-full bg-neutral-90 z-50 lg:relative lg:h-auto' : ' w-0 '}`}
				style={{ visibility: pinnedCard ? 'visible' : 'hidden' }}
			>
				<div className={'relative flex flex-col h-screen overflow-y-auto '}>
					<div
						className={'w-10 cursor-pointer aspect-square p-1 absolute right-6 top-8 bg-neutral-80 rounded z-10 ease-in-out duration-200 hover:bg-neutral-70  text-neutral-60 hover:text-primary-40'}
						onClick={() => {
							dispatch(changePinnedCard(null));
						}}>
						<XMarkIcon className="" />
					</div>
					{renderCurrentCardComponent()}
				</div>
			</div>
		</>
	)
		: null;
}