import { GroupGridItem as GroupGridItemType, AppGroup } from '@types';
import { useNavigate } from 'react-router-dom';

interface Props {
	group: AppGroup;
	onGroupClick: (group: AppGroup) => void;
}
export default function GroupGridItem({ group, onGroupClick }: Props) {
	const navigate = useNavigate();

	return (
		<div key={group.id} onClick={() => onGroupClick(group)} className={" hover:ring-neutral-70 cursor-pointer hover:ring-2 transition duration-300 rounded-xl overflow-clip bg-neutral-90 w-full aspect-[4/3] bg-cover bg-center bg-no-repeat flex flex-col justify-end text-left relative p-2"}
			style={{
				backgroundImage: `url(${group.group.banner_img ? group.group.banner_img.length > 0 ? group.group.banner_img : '/images/404.png' : '/images/404.png'})`
			}}
		>
			<div className=" bg-slate-800 w-full bg-opacity-30 rounded-xl p-2 text-white backdrop-blur-lg pr-10">
				<div className="z-10">
					<h4 className=" truncate">{group.group.name}</h4>
				</div>
			</div>
		</div>
	);
}