import { useQueryClient } from '@tanstack/react-query';

/**
 * Overloads make it so that the return type is either T or undefined depending on generics passed, T
 */

export default function useGetQueryData<T>(queryKey: string | number | Array<string | number>): T;
export default function useGetQueryData<T>(queryKey: string | number | Array<string | number>): T | undefined;

export default function useGetQueryData<T>(queryKey: string | number | Array<string | number>): T | undefined {
	const queryClient = useQueryClient();

	let data: T | undefined;

	Array.isArray(queryKey)
		? data = queryClient.getQueryData<T>(queryKey)
		: data = queryClient.getQueryData<T>([queryKey]);

	return data;
}