import { createSlice } from '@reduxjs/toolkit';

export interface PinnedCardState {
	currentPinnedCard: string | null,
	showSideBar: boolean,
	isLoading: boolean,
}

export const initialPinnedCardState = (): PinnedCardState => {
	return {
		currentPinnedCard: null,
		showSideBar: false,
		isLoading: false,
	};
};

export const pinnedCardSlice = createSlice({
	name: 'pinnedCard',
	initialState: initialPinnedCardState(),
	reducers: {
		changePinnedCard: (state, action) => {
			state.currentPinnedCard = action.payload;
		},
		togglePinnedCard: (state) => {
			state.showSideBar = !state.showSideBar;
		}
	},
});

export const { changePinnedCard, togglePinnedCard } = pinnedCardSlice.actions;
export default pinnedCardSlice.reducer;