import {
	useQuery as useTanstackQuery,
	QueryClient,
	QueryKey, UseQueryOptions, UseQueryResult
} from '@tanstack/react-query';

import { ConnectError, isConnectError } from 'errors';

export function useQuery<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
	options: UseQueryOptions<TQueryFnData, ConnectError, TData, TQueryKey>,
	queryClient?: QueryClient,
): UseQueryResult<TData, ConnectError> {
	const result = useTanstackQuery<TQueryFnData, ConnectError, TData, TQueryKey>(options, queryClient);
	const { error } = result;

	let connectError: ConnectError | undefined;
	if (error) {
		if (!isConnectError(error))
			connectError = new ConnectError('UseQuery', 'An error occurred while fetching data.', 'Unknown error.');
		else
			connectError = error;
	}

	return {
		...result,
		error: connectError,
	} as UseQueryResult<TData, ConnectError>;
}