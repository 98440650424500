import { useState, useRef, useEffect } from 'react';

interface ObserverSettings {
  threshold?: number | number[];
  root?: Element | null;
  rootMargin?: string;
}

const useDetectSticky = (
	ref?: React.RefObject<HTMLDivElement>,
	observerSettings: ObserverSettings = { threshold: [1] }
): [boolean, React.RefObject<HTMLDivElement>, React.Dispatch<React.SetStateAction<boolean>>] => {
	const [isSticky, setIsSticky] = useState(false);
	const newRef = useRef<HTMLDivElement | null>(null);

	ref ||= newRef;

	useEffect(() => {
		// Smells I just added a question mark to shut up compiler
		const cachedRef = ref?.current;

		if (!cachedRef) return;

		const observer = new IntersectionObserver(
			([e]) => setIsSticky(e.intersectionRatio < 1),
			observerSettings
		);

		observer.observe(cachedRef);

		return () => {
			observer.unobserve(cachedRef);
		};
	}, [ref, observerSettings]);

	return [isSticky, ref, setIsSticky];
};

export default useDetectSticky;