import { z } from 'zod';

export const newProductSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  price: z.number(),
  image: z.string(),
});

export type NewProductPayload = z.infer<typeof newProductSchema>;

export const updatedProductSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  price: z.number(),
  image: z.string(),
});

export type UpdatedProductPayload = z.infer<typeof updatedProductSchema>;

export const newProductVariationSchema = z.object({
  id: z.number(),
  product: z.number(),
  variation: z.number(),
});

export type NewProductVariationPayload = z.infer<typeof newProductVariationSchema>;

export const updatedProductVariationSchema = z.object({
  id: z.number(),
  product: z.number(),
  variation: z.number(),
});

export type UpdatedProductVariationPayload = z.infer<typeof updatedProductVariationSchema>;

export const newProductOptionSchema = z.object({
  id: z.number(),
  product: z.number(),
  option: z.number(),
});

export type NewProductOptionPayload = z.infer<typeof newProductOptionSchema>;

export const updatedProductOptionSchema = z.object({
  id: z.number(),
  product: z.number(),
  option: z.number(),
});

export type UpdatedProductOptionPayload = z.infer<typeof updatedProductOptionSchema>;

export const newOptionChoiceSchema = z.object({
  id: z.number(),
  product: z.number(),
  variation: z.number(),
});

export type NewOptionChoicePayload = z.infer<typeof newOptionChoiceSchema>;

export const updatedOptionChoiceSchema = z.object({
  id: z.number(),
  product: z.number(),
  variation: z.number(),
});

export type UpdatedOptionChoicePayload = z.infer<typeof updatedOptionChoiceSchema>;


