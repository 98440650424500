import React, { useEffect, useState } from 'react';
import { Disclosure, Popover } from '@headlessui/react';
import { Cog6ToothIcon, QuestionMarkCircleIcon, ArrowRightOnRectangleIcon, ComputerDesktopIcon, MoonIcon, SunIcon, } from '@icons';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {User} from '@types';

type ThemeType = 'light' | 'dark' | 'system';

export default function UserIcon() {
	const queryClient = useQueryClient();
	const user = queryClient.getQueryData<User>(['user']);

	const [settings, setSettings] = useState({
		theme: localStorage.getItem('theme') || 'system',
	});

	const handleThemeChange = (theme: ThemeType) => {
		localStorage.setItem('theme', theme);

		setSettings({...settings, theme: theme});
	};

	useEffect(() => {
		if (settings.theme === 'dark' || ((settings.theme === 'system') && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
			document.documentElement.classList.add('dark');
			document.documentElement.classList.remove('light');
		} else {
			document.documentElement.classList.add('light');
			document.documentElement.classList.remove('dark');
		}
	}, [settings]);

	const userInitials = () => {
		return `${user?.first_name?.charAt(0) ?? ''}${user?.last_name?.charAt(0) ?? ''}`;
	};

	const signOutUser = async () => {
		localStorage.removeItem('refresh-token');
		return queryClient.invalidateQueries({ queryKey: ['user'] });
	};

	return (
		<Popover as="div" className="relative inline-block text-left ">

			{/*+ New Button for dropdown of staff/self observation*/}
			<Popover.Button>
				<div className="relative inline-block">
					<span
						className="inline-flex h-10 w-10 border border-neutral-70 items-center justify-center rounded-full bg-neutral-80  ">
						<span className="text-lg font-medium leading-none text-neutral-40">
							{userInitials()}
						</span>
					</span>
					<span
						className="hover:animate-bounce absolute bottom-0 right-0 block h-3 w-3 rounded-full bg-primary-40 ring-2 ring-neutral-70 "/>
				</div>
			</Popover.Button>

			<Popover.Panel
				className="absolute top-0 right-0 z-20  w-56 translate-x-full -translate-y-full origin-top-right divide-y divide-neutral-70 rounded-md bg-neutral-80 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
				<Disclosure>
					<Disclosure.Button
						className="inline-flex w-full px-4 py-2 text-sm items-center space-x-2 ">
						<Cog6ToothIcon className="h-6 w-6 p-0.5"/>
						<div className="">
              Settings
						</div>
					</Disclosure.Button>
					<Disclosure.Panel>
						<div className="px-3 pt-2 flex flex-col space-y-0.5 pb-1.5 ">
							<div className="text-xs ">
                Theme:
							</div>

							<div className="inline-flex items-center bg-neutral-95 rounded w-20">
								<div className="relative flex items-center w-full">
									<div
										className={`absolute h-5 w-5 bg-neutral-70 rounded translate-margin duration-300 ${
											settings.theme === 'light' ? 'ml-[30px]' : settings.theme === 'dark' ? 'ml-[6px]' : 'ml-[54px]'
										}`}
									/>
								</div>
								<ul className="inline-flex space-x-2 p-1 ">
									<button className="flex relative" onClick={() => handleThemeChange('dark')}>
										<MoonIcon className="h-4 w-4 z-10"/>
									</button>

									<button className="flex relative" onClick={() => handleThemeChange('light')}>
										<SunIcon className="h-4 w-4"/>
									</button>

									<button className="flex relative" onClick={() => handleThemeChange('system')}>
										<ComputerDesktopIcon className="h-4 w-4 z-10"/>
									</button>
								</ul>
								{/* Dummy Item to center ul */}
								<div className="relative flex items-center w-full"/>
							</div>
						</div>
					</Disclosure.Panel>
				</Disclosure>

				<a
					className='inline-flex w-full px-4 py-2 text-sm items-center space-x-2 text-neutral-20 '
					href="https://support.creovia.io/hc/en-us/requests/new?ticket_form_id=17495557416973" target="_blank"
					rel="noopener noreferrer">
					<QuestionMarkCircleIcon className="h-6 w-6 p-0.5"/>
					<div className="">
            Support
					</div>
				</a>


				<a onClick={signOutUser}
					className=' inline-flex w-full px-4 py-2 text-sm items-center space-x-2 text-neutral-20 '
					href="/">
					<ArrowRightOnRectangleIcon className="h-6 w-6 p-0.5"/>
					<div className="">
            Sign Out
					</div>
				</a>
			</Popover.Panel>
		</Popover>
	);
}