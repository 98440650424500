import {Link} from 'react-router-dom';

export default function RegistrationConfirmation() {
	return (
		<div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
					<div className="bg-white dark:bg-neutral-12 px-6 py-8 shadow sm:rounded-lg">
						<div>
							<h2 className="mt-2 text-2xl font-bold tracking-tight dark:text-white">Success!</h2>
							<p className="dark:text-neutral-100 p-4"> Your account has been created. </p>
							<p className="dark:text-neutral-100 p-4"> If you have any issues, please email us at <a href={'mailto:support@creovia.io'} className="text-primary-40">support@creovia.io</a>.</p>

						</div>
						<div>
							<Link to="/" className="text-base font-medium text-primary-40 hover:text-primary-50">
								<span aria-hidden="true"> &larr;</span>
                                Back to Sign In
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}