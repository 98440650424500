import { SetStateAction, Dispatch, useState, useEffect } from 'react';
import { Topbar } from './components';

type layout = 'list' | 'grid';

interface ChildrenProps<T> {
	feed: Array<T>,
	filterBy: keyof T | null,
	setFilterBy: Dispatch<SetStateAction<keyof T | null>>,
	layout: layout,
	setLayout: Dispatch<SetStateAction<layout>>
}

interface FeedProps<T> {
	data: Array<T>;
	children: ({feed, filterBy, setFilterBy, layout, setLayout}: ChildrenProps<T>) => JSX.Element;
}

export default function Feed<T>({data, children}: FeedProps<T>) {
	const [feed, setFeed] = useState<Array<T>>([...data]);
	const [filterBy, setFilterBy] = useState<keyof T | null>(null);
	const [layout, setLayout] = useState<layout>('list');

	useEffect(() => {
		const filteredFeed = data.filter((item) => {
			if (filterBy === null) return true;
			return !!item[filterBy];
		});

		setFeed(filteredFeed);
	}, [filterBy]);

	return children({
		feed,
		filterBy,
		setFilterBy,
		layout,
		setLayout,
	});
}

Feed.Topbar = Topbar;
