import { useSearchParams } from 'react-router-dom';

export default function useURLQueryParams(params: Record<string, string>) {
	const [searchParams] = useSearchParams();

	const values: Record<string, string | number> = {};

	for (const key in params) {
		const value = searchParams.get(params[key]);
    
		values[key] = !Number.isNaN(Number(value)) && value !== '' ? Number(value) : value ?? '';
	}

	return values;
}