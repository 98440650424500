import {ComponentPropsWithoutRef, HTMLAttributes, PropsWithChildren, ReactNode} from 'react';
import { Listbox } from '@headlessui/react';
import {motion} from 'framer-motion';

interface OptionsProps extends PropsWithChildren<ComponentPropsWithoutRef<typeof Listbox>> {
	children: ReactNode;
}

export default function Options({ children, className, ...props }: OptionsProps) {
	return (
		<Listbox.Options {...props}>
			{({open}) => (
				<motion.div
					initial={{opacity: 0, y: -5}}
					animate={{opacity: open ? 1 : 0, y: open ? 0 : -5}}
					className={'absolute mt-1 bg-neutral-80 w-full text-left rounded-lg divide-y divide-neutral-70 max-h-52 overflow-y-auto border border-neutral-70 z-30 ' + className}>
					{children}
				</motion.div>

			)}
		</Listbox.Options>
	);
}