import {Listbox} from '@headlessui/react';
import { ReactNode, HTMLAttributes } from 'react';
import {ChevronDownIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {motion} from 'framer-motion';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
}

export default function Button({children, className, ...props}: ButtonProps) {

	return (
		<Listbox.Button className={'relative w-full min-h-10 rounded bg-neutral-80 py-2 pl-3 pr-10 text-left disabled:opacity-50 disabled:cursor-not-allowed ' + className} {...props}>
			{({open, disabled}) => (
				<>
					<span>
						{children}
					</span>
					<motion.div
						initial={{translateY: '-50%'}}
						animate={{scale: open ? [1, 1.5, 1] : 1}}
						transition={{duration: 0.2}}
						className={'absolute right-2 top-1/2'}>
						<ChevronUpDownIcon className={`h-5 w-5 ${open && !disabled ? 'text-primary-40' : ''}`} />
					</motion.div>
				</>
			)}
		</Listbox.Button>
	);
}