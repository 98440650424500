import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface PanelProps {
	show: boolean;
	onClose: () => void;
	children: React.ReactNode;
	className?: string;
}

export default function Content({ show, onClose, children, ...props }: PanelProps) {
	return (
		<Transition show={show} as={React.Fragment}>
			<Dialog as="div" onClose={onClose} className={'relative z-50'} {...props}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black/25" />
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className={'text-neutral-5 w-full flex justify-center'}>
								{children}
							</Dialog.Panel>

						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
}