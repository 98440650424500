import React from 'react';

interface RideIconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  titleId?: string;
}

function RideIcon({
	title,
	titleId,
	...props
}: RideIconProps, svgRef: React.LegacyRef<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" fill="currentColor" fillRule='evenodd' clipRule='evenodd'>
			<path className="st0" d="M208.5,411.5h784.9L891.8,166.8H308.2L208.5,411.5L208.5,411.5z M262.8,900.9V1026c0,48.9-39.9,88.8-87,88.8
	c-48.9,0-88.8-39.9-88.8-88.8V900.9H0v-368c0-65.3,50.8-117.8,116-121.5l117.8-279.1c14.5-23.6,38.1-41.7,70.7-47.1h592.8
	c32.6,5.4,54.4,23.6,70.7,47.1l117.8,279.1c63.4,3.6,114.2,56.2,114.2,121.5v368h-87V1026c0,48.9-39.9,88.8-87,88.8
	c-48.9,0-88.8-39.9-88.8-88.8V900.9L262.8,900.9z M1024.2,513c-47.1,0-85.2,38.1-85.2,85.2s38.1,85.2,85.2,85.2
	c45.3,0,83.4-38.1,83.4-85.2S1069.5,513,1024.2,513z M177.6,513c45.3,0,83.4,38.1,83.4,85.2s-38.1,85.2-83.4,85.2
	c-47.1,0-85.2-38.1-85.2-85.2S130.5,513,177.6,513z"/>
			<path className="st1" d="M-1219.1,411.5h784.9l-101.5-244.7h-583.7L-1219.1,411.5L-1219.1,411.5z M-1164.7,900.9V1026
	c0,48.9-39.9,88.8-87,88.8c-48.9,0-88.8-39.9-88.8-88.8V900.9h-87v-368c0-65.3,50.8-117.8,116-121.5l117.8-279.1
	c14.5-23.6,38.1-41.7,70.7-47.1h592.7c32.6,5.4,54.4,23.6,70.7,47.1l117.8,279.1c63.4,3.6,114.2,56.2,114.2,121.5v368h-87V1026
	c0,48.9-39.9,88.8-87,88.8c-48.9,0-88.8-39.9-88.8-88.8V900.9L-1164.7,900.9z M-403.4,513c-47.1,0-85.2,38.1-85.2,85.2
	s38.1,85.2,85.2,85.2c45.3,0,83.4-38.1,83.4-85.2S-358.1,513-403.4,513z M-1249.9,513c45.3,0,83.4,38.1,83.4,85.2
	s-38.1,85.2-83.4,85.2c-47.1,0-85.2-38.1-85.2-85.2S-1297.1,513-1249.9,513z"/>
		</svg>
	);
}

const ForwardRef = React.forwardRef(RideIcon);
export default ForwardRef;