import { useState, ReactNode, useEffect, useMemo } from 'react';
import useSearchQuery from './hooks/useSearchQuery';
import { MagnifyingGlassIcon } from '@icons';

type ChildrenProps<TData> = {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  onSearch: () => void;
  results: TData;
	setResults: React.Dispatch<React.SetStateAction<TData>>;
}

interface Props<TData> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onSubmit' | 'children' | 'results'> {
  data?: TData;
  query?: string;
  results?: TData;
  onResultsChange?: (newResults: TData) => void;
  onSubmit?: () => void;
  onQueryChange?: (newQuery: string) => void;
	delay?: number;
  children?: ReactNode | (({ query, setQuery, setResults, onSearch, results }: ChildrenProps<TData>) => ReactNode);
}

export default function Search<TData extends Array<unknown>>({
	data,
	query,
	results,
	onResultsChange,
	onQueryChange,
	onSubmit,
	delay=300,
	children,
	placeholder = 'Search',
	...props
}: Props<TData>) {
	const [_query, _setQuery] = useState(query || '');
	const [_results, _setResults] = useState<TData>(results || [] as unknown as TData);

	const searchResults = useMemo(() => {
		if (data) {
			return useSearchQuery(data, _query, { delay });
		}
		return [];
	}, [data, _query]);

	function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
		const newQuery = event.target.value;
		onQueryChange !== undefined && onQueryChange(newQuery);
		_setQuery(newQuery);
	}

	function handleSubmit() {
		onSubmit !== undefined && onSubmit();
	}

	useEffect(() => {
		query !== undefined && _setQuery(query);
	}, [query]);

	useEffect(() => {
		results !== undefined && _setResults(results);
	}, [results]);

	useEffect(() => {
		if (JSON.stringify(searchResults) !== JSON.stringify(_results)) {
			onResultsChange !== undefined && onResultsChange(searchResults as unknown as TData);
			_setResults(searchResults as unknown as TData);
		}
	}, [searchResults, _results, onResultsChange]);

	const childrenProps = useMemo(
		() => ({
			query: _query,
			setQuery: _setQuery,
			setResults: _setResults,
			onSearch: handleSubmit,
			results: _results,
		}),
		[_query, _setQuery, _setResults, handleSubmit, _results]
	);

	return (
		<div className="relative flex">
			<MagnifyingGlassIcon className='pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-neutral-40' />
			<input {...props}
				className='bg-neutral-80 placeholder-neutral-40 rounded h-8 pl-8 focus:outline-primary-60'
				placeholder={placeholder}
				type="text" value={_query} onChange={handleQueryChange} onSubmit={handleSubmit}
			/>
			{typeof children === 'function' ? (
				children(childrenProps)
			) : (
				children
			)}
		</div>
	);
}

interface ResultsProps<TData extends Array<unknown>> {
  results: TData;
	show?: boolean;
  children: ({ result, index }: { result: TData[number]; index: number }) => ReactNode;
}

function Results<TData extends Array<unknown>>({ results, show, children }: ResultsProps<TData>) {
	if (!show) return null;

	return (
		<ul>
			{results.length > 0 ? (
				results.map((result, index) => <li key={index}>{children({ result, index })}</li>)
			) : (
				<li>No results found</li>
			)}
		</ul>
	);
}

Search.Results = Results;
