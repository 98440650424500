import { ColumnType, sortType } from './Types';
import React, { CSSProperties, useEffect } from 'react';
import {
    Column,
    ColumnDef,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    Row,
    Table as TableType,
    useReactTable,
} from '@tanstack/react-table';
import TableHeader from './components/TableHeader/TableHeader';
import TableBody from './components/TableBody';
import Filters from './components/Filters/Filters';
import PaginationControls from './components/PaginationControls';
import { useVirtualizer } from '@tanstack/react-virtual';
import { mutateColumns, useTable } from './utils';
import LoadingSkeleton from './components/LoadingSkeleton/LoadingSkeleton';

interface TableProps<T> {
    columns: ColumnType<T>[];
    headerClass?: string;
    data: T[];
    className?: string;
    onRowClick?: (entry: T) => void;
    currentSort?: sortType;
    initSort?: {
        id: string;
        desc: boolean;
    };
    getTableData?: ({ table, rows, columns }: { table: TableType<T>; rows: Row<T>[]; columns: ColumnDef<T>[] }) => void;
    height?: number;
    isLoading?: boolean;
    select?: {
        selectedActionButton: (selected: T[], setSelectedRows: React.Dispatch<React.SetStateAction<Row<T>[]>>) => React.ReactNode;
    };
}

const Table = <T,>(props: TableProps<T>) => {
    const { columns, headerClass, data, className, onRowClick, initSort = null, getTableData, height = 600, isLoading } = props;

    const [selectedRows, setSelectedRows] = React.useState<Row<T>[]>([]);

    function getTable(): TableType<T> {
        return table;
    }

    const mutatedColumns = mutateColumns(columns, getTable);
    const { table } = useTable<T>(data, mutatedColumns);
    const parentRef = React.useRef(null);
    const { rows } = table.getSortedRowModel();

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 48,
        overscan: 150,
    });

    useEffect(() => {
        if (initSort) {
            table.setSorting([{ id: initSort.id, desc: initSort.desc }]);
        }
        table.getAllColumns().forEach((column) => {
            if (column.getCanHide()) column.toggleVisibility(false);
        });
    }, []);

    useEffect(() => {
        if (getTableData) {
            getTableData({
                table: table,
                rows: table.getPreSortedRowModel().rows,
                columns: table.getAllColumns(),
            });
        }
    }, [table.getPreSortedRowModel().rows]);

    return (
        <div className={`${props.select && selectedRows.length === 0 ? 'pb-[60px]' : ''}`}>
            <Filters table={table} />
            {/*<PaginationControls table={table}/>*/}

            <div
                ref={parentRef}
                className={'overflow-auto relative pb-2 w-full'}
                style={{
                    height: `${rowVirtualizer.getTotalSize() + 50}px`,
                    maxHeight: `${height}px`,
                    WebkitOverflowScrolling: 'touch',
                }}
            >
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        minWidth: 'max-content',
                    }}
                >
                    <table className={'grid text-neutral-5 rounded'}>
                        <TableHeader select={Boolean(props.select)} headerGroups={table.getHeaderGroups()} headerClass={headerClass} />
                        <TableBody
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            select={Boolean(props.select)}
                            virtualizedRows={rowVirtualizer.getVirtualItems()}
                            rowVirtualizer={rowVirtualizer}
                            onRowClick={onRowClick}
                            rows={rows}
                        />
                    </table>

                    {rows.length === 0 ? (
                        <div className="text-center fixed left-1/2 -translate-x-1/2 w-fit text-neutral-40 mt-5">Nothing here...</div>
                    ) : null}
                </div>
            </div>
            {props.select && selectedRows.length > 0 ? (
                <div className="w-full flex mt-5 gap-2">
                    <button className="bg-neutral-80 p-2 rounded" onClick={() => setSelectedRows([])}>
                        Clear Selection
                    </button>
                    {props.select.selectedActionButton(
                        selectedRows.map((row) => row.original),
                        setSelectedRows
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default Table;
