import { CreoviaLoad } from '@modules/common/components';
import { fetchStudentBehavior } from '@modules/magnify/Components/fetchDashboard';
import { useQuery } from '@tanstack/react-query';

export default function BehaviorTab({ studentId }: { studentId: number }) {
    const { data: studentBehavior, isLoading } = useQuery({
        queryKey: ['card', 'behavior', studentId],
        queryFn: () => fetchStudentBehavior(studentId),
    });

    if (isLoading) {
        return (
            <div className="relative h-52 w-full">
                <CreoviaLoad />
            </div>
        );
    }

    if (!studentBehavior) throw Error('failed to load Behavior');

    return (
        <div className="rounded-t-xl overflow-auto text-sm">
            <table className="w-full">
                <tr className="bg-neutral-70 ">
                    <th>Type</th>
                    <th>Incident</th>
                    <th>Submitted By</th>
                    <th>Resolution</th>
                </tr>

                {studentBehavior.length > 0
                    ? studentBehavior.map((behavior) => (
                          <tr>
                              <td className="py-2 px-2">{behavior.incident_type}</td>
                              <td className="py-2 px-2">{behavior.description}</td>
                              <td className="py-2 px-2">{behavior.submitted_by}</td>
                              <td className="py-2 px-2">{behavior.resolution}</td>
                          </tr>
                      ))
                    : null}
            </table>
            {studentBehavior.length === 0 ? <div className="w-full p-3 text-center">No Behavior Incidents</div> : null}
        </div>
    );
}
