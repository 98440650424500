import React from 'react';

interface PlayIconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  titleId?: string;
}

function PlayIcon({
	title,
	titleId,
	...props
}: PlayIconProps, svgRef: React.LegacyRef<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" fill="currentColor">
			<path className="st0" d="M422.3,914.1h357.9v39.3H422.3L422.3,914.1z"/>
			<path className="st0" d="M371,975H829v175H371L371,975z"/>
			<path className="st0" d="M991.8,119.7c-10.8-9.5-24.3-9.6-28.7-9.3h-95.7c0.3-11.5,0.4-20.2,0.4-25.2c0-39.2-35-35-35-35H367.3
		c0,0-35-4.2-35,35c0,5.1,0.1,13.8,0.4,25.2h-95.7c-4.4-0.3-17.9-0.2-28.7,9.3c-5.7,5.1-12.6,14.7-12.6,31.3
		c0,7.3,1.2,179.4,110,266.8c36.8,29.6,66.3,41.3,89.2,44.4c10.2,26.4,21.8,52,35.6,75.7c35.3,60.6,82.8,88,118.5,100.4
		c7.7,39.8,19.9,108.8,19.9,145.3c0,39-26.9,69.7-43.1,69.7h-58.5v39.2h265.5v-39.2h-58.5c-13.5,0-43.1-30.8-43.1-69.7
		c0-36.4,12.2-105.5,19.9-145.3c35.8-12.4,83.2-39.8,118.5-100.4c13.8-23.7,25.4-49.3,35.6-75.7c22.9-3.1,52.4-14.8,89.2-44.4
		c108.7-87.4,110-259.5,110-266.8C1004.4,134.4,997.5,124.8,991.8,119.7L991.8,119.7z M326.7,391.6c-96.3-77.5-97.4-234-97.4-240.6
		c0-3.9,0.8-5.7,1.3-6.1c0.8-0.7,3.2-0.9,3.8-1c0.7,0.1,1.3,0.1,2,0.1H334c3.5,67.3,14.5,177.3,47.5,280.4
		C367,419.3,348.9,409.4,326.7,391.6L326.7,391.6z M873.3,391.6c-21.6,17.3-39.8,27.4-54.8,32.8c33-103.1,44-213.1,47.5-280.4
		l99.3,0c1.1-0.1,3.4,0.2,4.1,0.9c0.4,0.4,1.3,2.2,1.3,6.1C970.7,157.7,969.6,314.2,873.3,391.6L873.3,391.6z"/>
		</svg>
	);
}

const ForwardRef = React.forwardRef(PlayIcon);
export default ForwardRef;