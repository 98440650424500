import React from 'react';

interface MagnifyIconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  titleId?: string;
}

function MagnifyIcon({
	title,
	titleId,
	...props
}: MagnifyIconProps, svgRef: React.LegacyRef<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" fill="currentColor">
			<path className="st1" d="M958.6,906.2c0,47.9-39.2,87.2-87.2,87.2h0c-47.9,0-87.2-39.2-87.2-87.2V293.8c0-47.9,39.2-87.2,87.2-87.2h0
		c47.9,0,87.2,39.2,87.2,87.2V906.2z M687.2,906.2c0,47.9-39.2,87.2-87.2,87.2h0c-47.9,0-87.2-39.2-87.2-87.2V474.7
		c0-47.9,39.2-87.2,87.2-87.2h0c47.9,0,87.2,39.2,87.2,87.2V906.2z M415.7,906.2c0,47.9-39.2,87.2-87.2,87.2h0
		c-47.9,0-87.2-39.2-87.2-87.2V653.5c0-47.9,39.2-87.2,87.2-87.2h0c47.9,0,87.2,39.2,87.2,87.2V906.2z"/>
		</svg>
	);
}

const ForwardRef = React.forwardRef(MagnifyIcon);
export default ForwardRef;