import React from 'react';

interface PerformIconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  titleId?: string;
}

function PerformIcon({
	title,
	titleId,
	...props
}: PerformIconProps, svgRef: React.LegacyRef<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" fill="currentColor">
				<path className="st0" d="M710.6,384.3c54.6-5,127.6-4.7,209.4,16.6c28.1,7.3,53.5,16.1,76.3,25.4c-18.7-46.6-56.2-122.8-128.8-196.1
			C791,153.1,710.5,114.5,663,95.8c11.6,21,23.1,45.7,32.4,74.1C724.2,257.4,718.7,335.7,710.6,384.3z"/>
				<path className="st0" d="M838.9,468.4H722.3c-60.6,0-109.7-49.1-109.7-109.7V253.3c0-86.9-70.5-157.4-157.4-157.4H278.7
			c-41.4,0-75,33.6-75,75v858.3c0,41.4,33.6,75,75,75h642.7c41.4,0,75-33.6,75-75V625.8C996.3,538.8,925.8,468.4,838.9,468.4z
			 M504.3,886.5c0,28.5-23.3,51.8-51.8,51.8h0c-28.5,0-51.8-23.3-51.8-51.8v-32.9c0-28.5,23.3-51.8,51.8-51.8h0
			c28.5,0,51.8,23.3,51.8,51.8V886.5z M651.8,886.5c0,28.5-23.3,51.8-51.8,51.8h0c-28.5,0-51.8-23.3-51.8-51.8V749
			c0-28.5,23.3-51.8,51.8-51.8h0c28.5,0,51.8,23.3,51.8,51.8V886.5z M795.6,886.5c0,28.5-23.3,51.8-51.8,51.8h0
			c-28.5,0-51.8-23.3-51.8-51.8V651.8c0-28.5,23.3-51.8,51.8-51.8h0c28.5,0,51.8,23.3,51.8,51.8V886.5z"/>
		</svg>
	);
}

const ForwardRef = React.forwardRef(PerformIcon);
export default ForwardRef;