import {ITimezoneOption, useTimezoneSelect} from 'react-timezone-select';
import {Selector} from '../Selector';
import {useEffect} from 'react';

interface TimezoneSelectorProps {
    value: ITimezoneOption | null
    onChange: (value: ITimezoneOption) => void;
}
export default function TimezoneSelector({value, onChange, ...props}: TimezoneSelectorProps) {
	const {options, parseTimezone} = useTimezoneSelect({labelStyle: 'abbrev'});

	useEffect(() => {
		if (!value) {
			onChange(parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone));
		}
	}, []);

	return (
		<div>
			<label className={'text-neutral-50'}>Timezone</label>
			<Selector className={'w-64'} selected={value}
				onChange={(e) => e ? onChange(parseTimezone(e)): null}>
				<Selector.Button
					className={' truncate bg-neutral-95 p-2 border-neutral-70 border'}>{value?.label}</Selector.Button>
				<Selector.Options>
					{options.map(option => (
						<Selector.Option key={option.label} value={option}>{option.label}</Selector.Option>
					))}
				</Selector.Options>
			</Selector>
		</div>
	);
}