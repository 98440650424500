import {ReactNode, useCallback, useState} from 'react';
import { throttle } from 'lodash';

interface FormProps extends Omit<React.HTMLAttributes<HTMLFormElement>, 'children'> {
  onSubmit: () => void;
  children: ((props: {isLoading: boolean}) => React.ReactNode) | ReactNode;
}

export default function Form({onSubmit, children, ...props}: FormProps) {
	const [isLoading, setIsLoading] = useState(false);

	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		setIsLoading(true);
		await onSubmit();
		setIsLoading(false);
	}

	const throttledHandleSubmit = useCallback(throttle((e: React.FormEvent<HTMLFormElement>) => handleSubmit(e), 3000), [onSubmit]);

	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		throttledHandleSubmit(e);
	};

	return (
		<form onSubmit={handleFormSubmit} {...props}>
			{typeof children === 'function'
				? children({isLoading})
				: children
			}
		</form>
	);
}