import callAPI from 'services';
import { payEndpoints } from 'services/api';
import { Product, Variation, Option, Choice } from '@modules/pay/types';
import { 
	updatedOptionChoiceSchema,
	UpdatedOptionChoicePayload,
	UpdatedProductPayload,
	NewProductVariationPayload,
	UpdatedProductVariationPayload,
	NewProductOptionPayload,
	UpdatedProductOptionPayload,
	NewOptionChoicePayload,
	updatedProductOptionSchema,
	newProductOptionSchema,
	updatedProductVariationSchema,
	newProductVariationSchema,
	updatedProductSchema,
	newProductSchema,
	NewProductPayload
} from '@modules/pay/schemas';

/**
 * Fetches the list of products
 */
export async function fetchProducts(payAdminGroupId: number | undefined) {
	return typeof payAdminGroupId === 'undefined'
		? Promise.reject(Error('No group admin ID provided'))
		: await callAPI<Array<Product>>(payEndpoints.products(payAdminGroupId));
}

/**
 * Creates a new product
 */
export async function createProduct(payload: NewProductPayload) {
	await newProductSchema.parse(payload);

	return await callAPI(payEndpoints.createProduct, {
		method: 'POST',
		body: JSON.stringify(payload),
	});
}

/**
 * Updates a product
 */
export async function updateProduct(payload: UpdatedProductPayload) {
	await updatedProductSchema.parse(payload);

	return await callAPI(payEndpoints.updateProduct, {
		method: 'PUT',
		body: JSON.stringify(payload),
	});
}

/**
 * Creates a product variation
 */
export async function createProductVariation(payload: NewProductVariationPayload) {
	await newProductVariationSchema.parse(payload);

	return await callAPI(payEndpoints.productVariation, {
		method: 'POST',
		body: JSON.stringify(payload),
	});
}

/**
 * Updates a product variation
 */
export async function updateProductVariation(payload: UpdatedProductVariationPayload) {
	await updatedProductVariationSchema.parse(payload);

	return await callAPI(payEndpoints.productVariation, {
		method: 'PUT',
		body: JSON.stringify(payload),
	});
}

/**
 * Deletes a product variation
 */
export async function deleteProductVariation(productVariationId: number) {
	return await callAPI(payEndpoints.productVariation, {
		method: 'DELETE',
		body: { variation_id: productVariationId },
	});
}

/**
 * Creates a product option
 */
export async function createProductOption(payload: NewProductOptionPayload) {
	await newProductOptionSchema.parse(payload);

	return await callAPI(payEndpoints.productOption, {
		method: 'POST',
		body: JSON.stringify(payload),
	});
}

/**
 * Updates a product option
 */
export async function updateProductOption(payload: UpdatedProductOptionPayload) {
	await updatedProductOptionSchema.parse(payload);

	return await callAPI(payEndpoints.productOption, {
		method: 'PUT',
		body: JSON.stringify(payload),
	});
}

/**
 * Deletes a product option
 */
export async function deleteProductOption(productOptionId: number) {
	return await callAPI(payEndpoints.productOption, {
		method: 'DELETE',
		body: { option_id: productOptionId },
	});
}

/**
 * Creates a choice for a product option
 */
export async function createProductOptionChoice(productOptionId: number, choice: NewOptionChoicePayload) {
	await updatedOptionChoiceSchema.parse(choice);

	return await callAPI(payEndpoints.productOptionChoice(productOptionId), {
		method: 'POST',
		body: JSON.stringify(choice),
	});
}

/**
 * Updates a choice for a product option
 */
export async function updateProductOptionChoice(productOptionId: number, payload: UpdatedOptionChoicePayload) {
	await updatedOptionChoiceSchema.parse(payload);

	return await callAPI(payEndpoints.productOptionChoice(productOptionId), {
		method: 'PUT',
		body: JSON.stringify({ choice_key: payload, new_choice_data: payload }),
	});
}

/**
 * Deletes a choice for a product option
 */
export async function deleteProductOptionChoice(productOptionId: number, choiceId: number) {
	return await callAPI(payEndpoints.productOptionChoice(productOptionId), {
		method: 'DELETE',
		body: { choice_id: choiceId },
	});
}