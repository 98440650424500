import React from 'react';
import useDetectSticky from './hooks/useDetectSticky';

interface StickyHeaderProps {
	sticky?: boolean;
	includeSearch?: boolean;
	children: React.ReactNode;
}

export default function StickyHeader({ children, ...props }: StickyHeaderProps) {
	const [isSticky, ref] = useDetectSticky();

	return (
		<div
			id={'sticky-header'}
			className={(isSticky ? ' isSticky ' : '') + ' sticky top-0 bg-neutral-95 z-20 px-4 @lg:px-8 py-4 backdrop-blur-md bg-opacity-60 '}
			ref={ref} // Assert ref as RefObject<HTMLDivElement>
			{...props}
		>
			{children}
		</div>
	);
}
