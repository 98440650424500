import React, { useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import {
    format,
    startOfWeek,
    addDays,
    startOfMonth,
    endOfMonth,
    endOfWeek,
    eachDayOfInterval,
    subMonths,
    addMonths,
    isSameMonth,
    isSameDay,
    isPast,
    isFuture,
    isWithinInterval,
    closestTo,
    isBefore,
    isAfter,
    getMonth,
} from 'date-fns';
import { Popover } from '@headlessui/react';

interface Props {
    date?: string;
    selectedRange?: { start: string; end: string };
    range?: boolean;
    futureOnly?: boolean;
    pastOnly?: boolean;
    onChange?: (arg0: string | { start: string; end: string }) => void;
}

export default function Calendar(props: Props) {
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(props.date ? new Date(props.date) ?? null : null);
    const [selectedRange, setSelectedRange] = React.useState<{
        start: Date | null;
        end: Date | null;
    }>(props.selectedRange ? { start: new Date(props.selectedRange.start), end: new Date(props.selectedRange.end) } : { start: null, end: null });
    const [activeDate, setActiveDate] = React.useState(new Date());
    const [hoveredDate, setHoveredDate] = React.useState<Date | null>(null);

    function handleRangeChange(date: Date) {
        if (selectedRange.start && isSameDay(date, selectedRange.start)) {
            setSelectedRange((prevState) => ({ start: null, end: prevState.end }));
        } else if (selectedRange.end && isSameDay(date, selectedRange.end)) {
            setSelectedRange((prevState) => ({ start: prevState.start, end: null }));
        } else if (selectedRange.start && date < selectedRange.start && !selectedRange.end) {
            setSelectedRange((prevState) => ({ start: date, end: prevState.start }));
        } else if (selectedRange.end && date > selectedRange.end && !selectedRange.start) {
            setSelectedRange((prevState) => ({ start: prevState.end, end: date }));
        } else if (!selectedRange.start) {
            setSelectedRange((prevState) => ({ start: date, end: prevState.end }));
        } else if (!selectedRange.end) {
            setSelectedRange((prevState) => ({ start: prevState.start, end: date }));
        } else {
            const closestDate = closestTo(date, [selectedRange.start, selectedRange.end]);
            if (closestDate && isSameDay(closestDate, selectedRange.start)) {
                setSelectedRange((prevState) => ({ start: date, end: prevState.end }));
            } else {
                setSelectedRange((prevState) => ({ start: prevState.start, end: date }));
            }
        }
    }

    // useEffect(() => {
    //   if (props.date) {
    //     setSelectedDate(new Date(props.date));
    //   }
    //   if (props.selectedRange) {
    //     setSelectedRange({ start: new Date(props.selectedRange.start), end: new Date(props.selectedRange.end) });
    //   }
    // }, [props.date, props.selectedRange]);

    function Header({ activeDate }: { activeDate: Date }) {
        const [activeYear, setActiveYear] = React.useState(activeDate.getFullYear());
        return (
            <div>
                <div className={'flex justify-between items-center border border-neutral-80 py-1 relative z-30'}>
                    <button className={'p-2 '} onClick={() => setActiveDate((prevActiveDate) => subMonths(prevActiveDate, 1))}>
                        <ChevronLeftIcon className={'h-6 w-6'} />
                    </button>
                    <div className={'inline-flex items-baseline gap-1'}>
                        <Popover className={''}>
                            <Popover.Button className={'hover:text-primary-40 font-semibold'}>
                                <span>{format(activeDate, 'MMMM')}</span>
                            </Popover.Button>
                            <Popover.Panel
                                className={
                                    'absolute bg-neutral-90 p-2 mt-3 border border-neural-70 w-screen max-w-xs left-1/2 -translate-x-1/2 shadow'
                                }
                            >
                                <div className={'grid grid-cols-3 gap-1'}>
                                    {Array.from({ length: 12 }, (_, i) => i).map((month) => {
                                        return (
                                            <button
                                                key={month}
                                                onClick={() => setActiveDate(new Date(activeDate.getFullYear(), month, 1))}
                                                className={`p-2 text-sm hover:bg-primary-40/20 rounded-lg ${
                                                    activeDate.getMonth() === month ? 'bg-primary-40 text-neutral-90' : ''
                                                }`}
                                            >
                                                {format(new Date(activeDate.getFullYear(), month, 1), 'MMMM')}
                                            </button>
                                        );
                                    })}
                                </div>
                            </Popover.Panel>
                        </Popover>
                        <Popover>
                            <Popover.Button>
                                <span className={'font-semibold hover:text-primary-40'}>{format(activeDate, 'yyyy')}</span>
                            </Popover.Button>
                            <Popover.Panel
                                className={
                                    'absolute bg-neutral-90 p-2 mt-3 border border-neural-70 w-screen max-w-xs left-1/2 -translate-x-1/2 shadow flex items-center'
                                }
                            >
                                <div className={'px-1'}>
                                    <button onClick={() => setActiveYear((prevState) => prevState - 12)}>
                                        <ChevronLeftIcon className={'h-6 w-6'} />
                                    </button>
                                </div>
                                <div className={'grid grid-cols-3 w-full gap-1'}>
                                    {Array.from({ length: 12 }, (_, i) => activeYear - 6 + i).map((year) => {
                                        return (
                                            <button
                                                key={year}
                                                onClick={() => setActiveDate(new Date(year, activeDate.getMonth()))}
                                                className={`p-2 text-sm hover:bg-primary-40/20 rounded-lg ${
                                                    activeDate.getFullYear() === year ? 'bg-primary-40 text-neutral-90' : ''
                                                }`}
                                            >
                                                {year}
                                            </button>
                                        );
                                    })}
                                </div>
                                <div className={'px-1'}>
                                    <button onClick={() => setActiveYear((prevState) => prevState + 12)}>
                                        <ChevronRightIcon className={'h-6 w-6'} />
                                    </button>
                                </div>
                            </Popover.Panel>
                        </Popover>
                    </div>
                    <button className={'p-2'} onClick={() => setActiveDate((prevActiveDate) => addMonths(prevActiveDate, 1))}>
                        <ChevronRightIcon className={'h-6 w-6'} />
                    </button>
                </div>
            </div>
        );
    }

    function WeekDayNames({ activeDate }: { activeDate: Date }) {
        const weekStartDate = startOfWeek(activeDate);
        const weekDayNames = Array.from({ length: 7 }, (_, i) => format(addDays(weekStartDate, i), 'EEE'));
        return (
            <>
                {weekDayNames.map((day) => (
                    <span key={day} className={'p-2 text-sm font-semibold text-neutral-60'}>
                        {day.toUpperCase()}
                    </span>
                ))}
            </>
        );
    }

    function Dates({ activeDate }: { activeDate: Date }) {
        const startOfTheSelectedMonth = startOfMonth(activeDate);
        const endOfTheSelectedMonth = endOfMonth(activeDate);
        const startDate = startOfWeek(startOfTheSelectedMonth);
        const endDate = endOfWeek(endOfTheSelectedMonth);
        const datesOfSelectedMonth = eachDayOfInterval({ start: startDate, end: endDate });

        return (
            <>
                {datesOfSelectedMonth.map((date) => {
                    return (
                        <button
                            key={date.toISOString()}
                            disabled={(props.futureOnly && isPast(date)) || (props.pastOnly && isFuture(date))}
                            onMouseEnter={() => props.range && setHoveredDate(new Date(date.toISOString()))}
                            onMouseLeave={() => props.range && setHoveredDate(null)}
                            className={`
                                ${isSameMonth(date, activeDate) ? '' : 'text-neutral-70'}
                                ${
                                    selectedDate && isSameDay(date, selectedDate)
                                        ? 'bg-primary-40 text-neutral-90'
                                        : selectedRange.start && isSameDay(date, selectedRange.start)
                                        ? 'bg-primary-40 text-neutral-90 rounded-r-none'
                                        : selectedRange.end && isSameDay(date, selectedRange.end)
                                        ? 'bg-primary-40 text-neutral-90 rounded-l-none hover:bg-primary-40'
                                        : selectedRange.start &&
                                          selectedRange.end &&
                                          isWithinInterval(date, { start: selectedRange.start, end: selectedRange.end })
                                        ? 'bg-primary-40/20 text-primary-20 rounded-none'
                                        : `${
                                              isWithinInterval(date, {
                                                  start: selectedRange.start ? selectedRange.start : selectedRange.end ? selectedRange.end : '', // Modified this line
                                                  end: hoveredDate ?? '',
                                              }) &&
                                              (!selectedRange.start || !selectedRange.end)
                                                  ? 'bg-primary-40/20 rounded-none'
                                                  : 'enabled:hover:bg-neutral-80'
                                          }`
                                }
                                 p-2 rounded-full aspect-square disabled:text-neutral-70
                                `}
                            onClick={() => {
                                if (props.range) handleRangeChange(date);
                                else setSelectedDate(date);
                            }}
                        >
                            {format(date, 'd')}
                        </button>
                    );
                })}
            </>
        );
    }

    useEffect(() => {
        if (!props.onChange) return;
        if (props.range && (selectedRange.start !== undefined || selectedRange.end !== undefined)) {
            props.onChange({
                start: selectedRange.start ? selectedRange.start.toISOString() : '',
                end: selectedRange.end ? selectedRange.end.toISOString() : '',
            });
        } else if (selectedDate !== undefined) {
            if (selectedDate !== null && isNaN(+selectedDate)) return;
            props.onChange(selectedDate !== null ? selectedDate.toISOString() : '');
        }
    }, [selectedRange, selectedDate]);

    useEffect(() => {
        if (selectedRange.start && !selectedRange.end && hoveredDate && isBefore(hoveredDate, selectedRange.start)) {
            setSelectedRange((prevState) => ({ start: null, end: prevState.start }));
        } else if (selectedRange.end && !selectedRange.start && hoveredDate && isAfter(hoveredDate, selectedRange.end)) {
            setSelectedRange((prevState) => ({ start: prevState.end, end: null }));
        }
    }, [hoveredDate]);

    return (
        <div className={'text-neutral-5 w-80 bg-neutral-90'}>
            <Header activeDate={activeDate} />
            <section className={'grid-cols-7 grid text-center border-x border-b border-neutral-80'}>
                <WeekDayNames activeDate={activeDate} />
                <Dates activeDate={activeDate} />
            </section>
        </div>
    );
}
