import { CartItem } from './components';
import {useCart} from '@modules/pay/hooks';

export default function CartPinnedCard() {
	const cart = useCart();

	if (!cart.data) throw new Error('Trouble fetching cart.');

	const totalPrice = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	}).format(cart.data.total / 100);

	return (
		<>
			<div className={'flex flex-col justify-between h-full pb-10'}>
				<div>
					<h1 className={'p-2 mt-5 text-2xl font-semibold'}>Cart</h1>
					<div className={'mx-2 p-2 rounded bg-neutral-95'} style={{ }}>
						<h2 className={'text-neutral-40'}>Item(s)</h2>
						<div className={'mt-3 flex flex-col gap-2'}>
							{cart.data.items.map(item => (
								<CartItem item={item} key={item.id}/>
							))}
						</div>
					</div>
				</div>
				<div>
					<div className='flex justify-between mx-2 p-2 rounded bg-neutral-95'>
						<span>
					Total:
						</span>
						<span>
							{`${totalPrice}`}
						</span>
					</div>
					<button className={'p-2 font-semibold w-full bg-primary-40 text-neutral-90'}>
				Checkout
					</button>
				</div>
			</div>
		</>
	);
}