import { axiosInstance, refreshAccessToken } from './axiosInstance';
import axios from 'axios';
import { authEndpoints } from 'services/api';
import { ConnectError } from '../errors';

export default async function callAPI<R>(url: string, options?: object, sendFullResponse?: boolean): Promise<R> {
	const cause = `url: ${url} options: ${options}`;
	try {
		const response = await axiosInstance({ url, ...options });
		if (response.status >= 200 && response.status < 300) {
			return response.data.results ? sendFullResponse ? response.data : response.data.results : response.data;
		}

		// I don't see how code could reach this point, but it would mean the server didn't return a 2xx status code and axios didn't throw an error
		throw new ConnectError('RequestError', 'Something went wrong.', cause);

	} catch (error: unknown) {
		if (axios.isAxiosError(error)) {
			const responseData = error.response?.data as ({ detail?: string; message?: string; errors?: Array<string> });

			// If we are trying to login, just pass the error through and let it be handled elsewhere
			if (error.config.url === authEndpoints.login && error.response?.status === 401) {
				throw new ConnectError('RequestError', responseData.message ?? responseData.detail ?? 'Something went wrong.', cause);
			}


			if (!error.response?.status) {
				throw new ConnectError('RequestError', 'Unable to communicate with Connect servers.', cause);
			}

			{ /* Typeguard */ }
			if (error?.response?.status === 401) { // Is access token expired?

				await refreshAccessToken(); // Try to refresh the access token

				try { // Try the request again
					const response = await axiosInstance({ url, ...options });
					if (response.status >= 200 && response.status < 300) {

						return response.data.results ? sendFullResponse ? response.data : response.data.results : response.data;
					}

					throw new ConnectError('RequestError', responseData.message ?? responseData.detail ?? 'Something went wrong.', cause);

				} catch (error) { // Not sure what could cause this error - maybe if you refresh the token but then it just so happens the endpoint is broken?
					if (axios.isAxiosError(error)) {
						throw new ConnectError('RequestError', error.message, cause);
					}
					console.error('Hmm, this should not happen:', error); // If this happens, the error is probably caused by code instead of a request failing
				}

			} else {
				throw new ConnectError('RequestError', responseData.message ?? responseData.detail ?? 'Something went wrong.', cause);
			}

			throw new ConnectError('RequestError', 'Something went wrong.', cause);

		}

		// I don't think code should reach this point bc axios should throw an error if the request fails
		throw new ConnectError('InternalError', `Unhandled Request Error: ${error}`, cause);
	}
}


