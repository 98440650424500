import { useQuery } from '@tanstack/react-query';
import { fetchStudentStatus } from '@modules/magnify/Components/fetchDashboard';
import { DashboardCardType, DashboardDataType } from '@modules/magnify/Components/types/DashboardTypes';
import React, { ReactElement } from 'react';
import { Tab } from '@headlessui/react';
import { CreoviaLoad } from '@modules/common/components';
import BehaviorTab from './components/behaviorTab';
import GradesTab from './components/gradesTab';
import AssessmentsTab from './components/assessmentsTab';

type MagnifyPinnedCardProps = {
    student: DashboardDataType;
    cardURL: string;
};

export default function MagnifyPinnedCard({ student, cardURL }: MagnifyPinnedCardProps) {
    const { data: studentData, isLoading } = useQuery({
        queryKey: ['card', student.id],
        queryFn: () => fetchStudentStatus(student.profile_id),
    });

    if (isLoading) return <CreoviaLoad />;

    if (!studentData) throw new Error('card data is undefined');

    return (
        <div className="flex flex-col w-full items-center py-8 px-2">
            <div className="flex flex-col items-center mb-5">
                <img
                    src={studentData.img_url ? studentData.img_url : '          /images/user_grey.jpg'}
                    loading="lazy"
                    onError={(e) => {
                        e.currentTarget.onerror = null; // prevents looping
                        e.currentTarget.src = '/images/user_gray.jpg';
                    }}
                    className="w-32 object-cover aspect-square rounded-full mb-2"
                ></img>
                <h3 className="text-lg font-semibold">{studentData.name_last_first}</h3>
                <h4>{studentData.campus}</h4>
                <div>
                    <p>Grade: {studentData.grade_level}</p>
                </div>
            </div>
            <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-neutral-80 p-1 w-full">
                    {['Overview', 'Behavior', 'Grades', 'Assessments'].map((title) => {
                        return (
                            <Tab
                                className={({ selected }) =>
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5  ' +
                                    `${selected ? 'bg-primary-40 text-neutral-95 shadow' : ' hover:bg-neutral-70 '}`
                                }
                            >
                                {title}
                            </Tab>
                        );
                    })}
                </Tab.List>
                <Tab.Panels className="mt-2 w-full bg-neutral-80  rounded-xl ">
                    <Tab.Panel className={'divide-y divide-neutral-60 text-sm px-2'}>
                        <div className="flex w-full justify-between py-2">
                            <span>Risk Factor</span>
                            <span>{studentData.risk_factor}</span>
                        </div>
                        <div className="flex w-full justify-between py-2">
                            <span>Grade</span>
                            <span>{studentData.grade_level}</span>
                        </div>
                        <div className="flex w-full justify-between py-2">
                            <span>Campus</span>
                            <span>{studentData.campus}</span>
                        </div>
                        <div className="flex w-full justify-between py-2">
                            <span>Tardies</span>
                            <span>{studentData.tardies}</span>
                        </div>
                        <div className="flex w-full justify-between py-2">
                            <span>Absences</span>
                            <span>{studentData.absences}</span>
                        </div>
                        <div className="flex w-full justify-between py-2">
                            <span>Number of Ds</span>
                            <span>{studentData.number_of_ds}</span>
                        </div>
                        <div className="flex w-full justify-between py-2">
                            <span>Number of Fs</span>
                            <span>{studentData.number_of_fs}</span>
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <BehaviorTab studentId={student.profile_id} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <GradesTab studentId={student.profile_id} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <AssessmentsTab studentId={student.profile_id} />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}
