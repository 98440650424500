import {ArrowLeftIcon} from '@heroicons/react/20/solid';
import {Link} from 'react-router-dom';
import {useSendRegistrationEmail} from '@modules/auth/hooks';

export default function RegistrationConfirmation({ email }: { email: string }) {
	const { mutate: sendRegistrationEmail } = useSendRegistrationEmail();

	function handleResendEmail() {
		sendRegistrationEmail(email);
	}

	return (
		<div className='flex h-screen mx-4'>
			<div className='flex flex-col m-auto justify-center rounded-md bg-neutral-80 text-neutral-5'>
				<div className='m-5 px-8'>
					<div className='flex items-center text-primary-30 pb-6'>
						<ArrowLeftIcon  className='h-5 w-5'/>
						<Link to='/register'>
							<a className='text-sm ml-2'>Go Back</a>
						</Link>
					</div>
					<div className='space-y-1 my-4'>
						<h2 className='text-xl'> Check your inbox!</h2>
						<h3 className='text-md'>We&apos;ve emailed a sign up link to {email}</h3>
					</div>
				</div>
				<div className='flex flex-wrap text-sm my-7 justify-center'>
					<p>Didn&apos;t receive the link?</p>
					<a onClick={handleResendEmail} className='ml-2 text-primary-30 cursor-pointer'>Resend email</a>
				</div>
			</div>
		</div>
	);
}