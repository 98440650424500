import {DateInput, DateSegment, Label, TimeField, TimeFieldProps, ValidationResult, Text, FieldError} from 'react-aria-components';
import {TimeValue} from 'react-aria';
import {useEffect, useState} from 'react';
import TimezoneSelect, {allTimezones, type ITimezone, ITimezoneOption, useTimezoneSelect} from 'react-timezone-select';
import {Selector} from '../Selector';

interface TimeSelectorProps<T extends TimeValue> extends TimeFieldProps<T> {
	label?: string;
	description?: string;
	errorMessage?: string | ((validation: ValidationResult) => string);
}

const labelStyle = 'abbrev';
const timezones = {
	...allTimezones,
};
export default function TimeSelector<T extends TimeValue>({ label, description, errorMessage, ...props }: TimeSelectorProps<T>){
	const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

	return (
		<div className={'flex gap-1 items-end'}>
			<TimeField {...props}>
				<Label className={'text-neutral-50'}>{label ?? 'Time'}</Label>
				<DateInput className={'flex gap-1 p-2 border border-neutral-70 rounded'}>
					{segment =>  <DateSegment className={'px-1'} segment={segment}/>}
				</DateInput>
				{description && <Text slot="description">{description}</Text>}
				<FieldError>{errorMessage}</FieldError>
			</TimeField>
		</div>
	);
}