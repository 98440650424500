import { z } from 'zod';

export const newListingSchema = z.object({
	id: z.number(),
	product: z.array(z.number()),
	variations: z.array(z.number()),
});

export type NewListingPayload = z.infer<typeof newListingSchema>;

export const updatedListingSchema = z.object({
  id: z.number(),
  product: z.number(),
  variations: z.array(z.number()),
});

export type UpdatedListingPayload = z.infer<typeof updatedListingSchema>;