import {ReactNode} from 'react';

interface TabProps {
	isSelected: boolean;
  onClick: () => void;
  children: ReactNode;
}
export default function Tab({isSelected, onClick, children}: TabProps) {

	return (
		<div onClick={onClick} className={`${isSelected ? 'underline' : null} flex items-center justify-center cursor-pointer`}>
			{children}
		</div>
	);
}