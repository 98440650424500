import React, {createContext, useState, useRef, useEffect} from 'react';

export const AudioContext = createContext({
	playAudio: (src: string): void => undefined,
	pauseAudio: (): void => undefined,
	setVolume: (volume: number): void => undefined,
	setSource: (src: string): void => undefined,
});


interface Props {
  children: React.ReactNode;
}

export default function AudioProvider({ children }: Props) {
	const audioRef = useRef<HTMLAudioElement>();
	const playPromise = useRef<Promise<void>>();

	useEffect(() => {
		audioRef.current = new Audio();
	}, [audioRef]);

	// If you want to make this better - move src to state and if it is unchanged, pause and set currentTime to 0 instead of setting src
	function playAudio(src: string) {
		// https://developer.chrome.com/blog/play-request-was-interrupted
		// TL;DR - when you play(), you should wait for the promise to resolve before you can set the src again
		if (playPromise.current !== undefined) {
			playPromise.current.then(() => {
				if (!audioRef.current) throw new Error('Cannot play audio - no audio player created');

				audioRef.current.src = src;
				audioRef.current.load();

				playPromise.current = audioRef.current.play()

			});
		} else {
			if (!audioRef.current) throw new Error('Cannot play audio - no audio player created');

			audioRef.current.src = src;
			audioRef.current.load();
				
			playPromise.current = audioRef.current.play()

		}
	}

	function pauseAudio() {
		if (!audioRef.current?.src) throw new Error('Cannot pause audio - no audio source set');

		audioRef.current.pause();
	}

	function setVolume(volume: number) {
		if (volume < 0 || volume > 1) throw new Error('Volume must be between 0 and 1');
		if (!audioRef.current) throw new Error('Cannot set volume - no audio source set');

		audioRef.current.volume = volume;
	}

	function setSource(src: string) {
		if (!audioRef.current) throw new Error('Cannot set source - no audio source set');

		audioRef.current.src = src;
	}

	function stopAudio() {
		if (!audioRef.current?.src) throw new Error('Cannot stop audio - no audio source set');

		audioRef.current.pause();

	}

	const value = {
		playAudio,
		pauseAudio,
		setVolume,
		setSource,
		audio: audioRef.current,
	};

	return (
		<AudioContext.Provider value={value}>
			{children}
		</AudioContext.Provider>
	);
}

export function useAudio() {
	return React.useContext(AudioContext);
}