import React from 'react';

interface AnnounceIconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  titleId?: string;
}

function AnnounceIcon({
	title,
	titleId,
	...props
}: AnnounceIconProps, svgRef: React.LegacyRef<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" fill="currentColor" fillRule={"evenodd"}>
			<path className="st0" d="M1200,257.2v828.5c0,46.8-38.8,85.6-85.6,85.6c-47.3,0-85.6-38.8-85.6-85.6V257.2H1200L1200,257.2z
	 M799.7,942.8c16.1,0,28.9,12.8,28.9,28.4c0,16.1-12.8,28.9-28.9,28.9H171.3c-15.6,0-28.4-12.8-28.4-28.9
	c0-15.6,12.8-28.4,28.4-28.4H799.7z M799.7,799.9c16.1,0,28.9,12.8,28.9,28.4c0,16.1-12.8,28.9-28.9,28.9H171.3
	c-15.6,0-28.4-12.8-28.4-28.9c0-15.6,12.8-28.4,28.4-28.4H799.7z M800.2,642.8c15.6,0,28.4,12.8,28.4,28.4
	c0,16.1-12.8,28.9-28.4,28.9H486c-16.1,0-28.9-12.8-28.9-28.9c0-15.6,12.8-28.4,28.9-28.4H800.2z M799.7,499.9
	c16.1,0,28.9,12.8,28.9,28.9c0,15.6-12.8,28.4-28.9,28.4H485.5c-15.6,0-28.4-12.8-28.4-28.4c0-16.1,12.8-28.9,28.4-28.9H799.7z
	 M171.3,485.7h171.3c16.1,0,28.9,12.8,28.9,28.4v171.8c0,15.6-12.8,28.4-28.9,28.4H171.3c-15.6,0-28.4-12.8-28.4-28.4V514.1
	C142.9,498.5,155.7,485.7,171.3,485.7L171.3,485.7z M171.3,171.5h628.4c16.1,0,28.9,12.8,28.9,28.4v171.3
	c0,16.1-12.8,28.9-28.9,28.9H171.3c-15.6,0-28.4-12.8-28.4-28.9V199.9C142.9,184.3,155.7,171.5,171.3,171.5L171.3,171.5z M971.4,57
	v1028.7c0,32.2,10.9,61.5,28.9,85.6H114.5c-62.9,0-114.5-51.6-114.5-114L0,57c0-15.6,12.8-28.4,28.9-28.4h914.2
	C958.7,28.6,971.4,41.4,971.4,57L971.4,57z"/>
		</svg>
	);
}

const ForwardRef = React.forwardRef(AnnounceIcon);
export default ForwardRef;