import { CreoviaLoad } from '@modules/common/components';
import { fetchStudentAssessments } from '@modules/magnify/Components/fetchDashboard';
import { useQuery } from '@tanstack/react-query';

export default function AssessmentsTab({ studentId }: { studentId: number }) {
    const { data: studentAssessments, isLoading } = useQuery({
        queryKey: ['card', 'assessments', studentId],
        queryFn: () => fetchStudentAssessments(studentId),
    });

    if (isLoading) {
        return (
            <div className="relative h-52 w-full">
                <CreoviaLoad />
            </div>
        );
    }

    if (!studentAssessments) throw Error('failed to load Behavior');

    return (
        <div className="rounded-t-xl overflow-auto text-sm">
            <table className="w-full">
                <tr className="bg-neutral-70 ">
                    <th>Year</th>
                    <th>Name</th>
                    <th>Benchmark</th>
                </tr>

                {studentAssessments.length > 0
                    ? studentAssessments.map((assessment) => (
                          <tr>
                              <td className="py-2 px-2">{assessment.test_year}</td>
                              <td className="py-2 px-2">{assessment.assessment_name}</td>
                              <td className="py-2 px-2">{assessment.benchmark}</td>
                          </tr>
                      ))
                    : null}
            </table>
            {studentAssessments.length === 0 ? <div className="w-full p-3 text-center">No Assessments</div> : null}
        </div>
    );
}
