import {Header} from '@tanstack/react-table';
import {useState} from 'react';

interface ColumnResizerProps<T> {
    header: Header<T, unknown>;
}
export default function ColumnResizer<T>({header}: ColumnResizerProps<T>) {
	const [isResizing, setIsResizing] = useState(false);

	const handleMouseDownInput = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		setIsResizing(true);
		header.getResizeHandler()(e);
	};

	return (
		<div
			className={`absolute top-1/2 -translate-y-1/2 ${header.column.getIsResizing() ? 'scale-y-150 bg-primary-60 ' : ''} transition duration-200 right-0 h-6 rounded-full bg-primary-50 w-1 hover:bg-primary-60 cursor-col-resize`}
			onDoubleClick={() => header.column.resetSize()}
			onMouseDown={handleMouseDownInput}
			onTouchStart={header.getResizeHandler()}
		/>
	);
}