import callAPI from 'services';
import { payEndpoints } from 'services/api';
import {Cart, CartItem, Choice, Option} from '@modules/pay/types';
import { } from '@modules/pay/schemas';

/**
 * Fetches the user's cart
 */
export async function fetchCart() {
	return await callAPI<Cart>(payEndpoints.cart);
}

/**
 * Adds a product listing to the user's cart
 */
export async function addToCart(productVariationId: number, selectedOptions: Array<{ [x: Option['name']]: Choice }>, quantity?: number) {
	quantity = quantity || 1;

	return await callAPI(payEndpoints.addToCart, {
		method: 'POST',
		data: {
			variation_id: productVariationId,
			selected_choice_ids: selectedOptions.map(option => Object.values(option)[0].id),
			quantity
		},
	});
}

/**
 * Removes a product from the user's cart
 * @param cartItemId - The ID of the cart item to remove from the cart
 */
export async function removeFromCart(cartItemId: number) {
	return await callAPI(payEndpoints.removeFromCart(cartItemId), {
		method: 'DELETE',
	});
}

/**
 * Updates the quantity of a product in the user's cart
 * @param cartItemId - The ID of the cart item to update
 * @param quantity - The new quantity of the product
 */
export async function updateCartItem(cartItemId: number, quantity: number) {
	return await callAPI<CartItem>(payEndpoints.updateCartItem(cartItemId), {
		method: 'PATCH',
		data: {
			quantity
		},
	});
}