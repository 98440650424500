import {Combobox} from '@headlessui/react';
import {ReactNode, useState} from 'react';
import {Input, Options, Option, Button} from './components';

interface MultiSelectorProps<T> {
    selected: T[] | undefined;
    onChange: (value: T[]) => void;
    disabled?: boolean;
    className?: string;
    children: (props: {query: string; setQuery: (query: string) => void}) => JSX.Element;
}
export default function MultiSelector<T>({selected, onChange, children, disabled, className, ...props}: MultiSelectorProps<T>) {
	const [query, setQuery] = useState('');

	return (
		<Combobox as={'div'} value={selected} onChange={onChange} disabled={disabled} className={'relative disabled-opacity-20 ' + className} {...props}>
			{
				children({query, setQuery})
			}
		</Combobox>
	);
}

MultiSelector.Input = Input;
MultiSelector.Button = Button;
MultiSelector.Options = Options;
MultiSelector.Option = Option;
