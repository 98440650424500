import * as queryFn from '@modules/pay/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Option, Choice } from '@modules/pay/types';
import { NewListingPayload, NewProductPayload, NewProductVariationPayload, UpdatedListingPayload, UpdatedProductPayload, UpdatedProductVariationPayload } from '@modules/pay/schemas';

export function usePay() {
	return useQuery({
		queryKey: payKeys.meta(),
		queryFn: queryFn.pay
	});
}

/**
 * useQuery wrapper for fetching the list of products
 */
export function useListings() {
	return useQuery({
		queryKey: payKeys.listings(),
		queryFn: queryFn.fetchListings
	});
}

/**
 * useQuery wrapper for fetching a product listing
 */
export function useListing(id: number) {
	return useQuery({
		queryKey: payKeys.listing(id),
		queryFn: () => queryFn.fetchListing(id)
	});
}

/**
 *
 */
export function useCreateListing() {
	return useMutation({
		mutationFn: (payload: NewListingPayload) => queryFn.createListing(payload),
	});
}

/**
 *
 */
export function useUpdateListing() {
	return useMutation({
		mutationFn: (payload: UpdatedListingPayload) => queryFn.updateListing(payload),
	});
}

/**
 *
 */
export function useDeleteListing() {
	return useMutation({
		mutationFn: (productListingId: number) => queryFn.deleteListing(productListingId),
	});
}

/**
 * useQuery wrapper for fetching the list of products
 */
export function useProducts(payAdminGroupId: number | undefined) {
	return useQuery({
		queryKey: payKeys.products(),
		queryFn: () => queryFn.fetchProducts(payAdminGroupId)
	});
}

/**
 * useMutation wrapper for creating a product
 */
export function useCreateProduct() {
	return useMutation({
		mutationFn: (payload: NewProductPayload) => queryFn.createProduct(payload),
	});
}

/**
 * useMutation wrapper for updating a product
 */
export function useUpdateProduct() {
	return useMutation({
		mutationFn: (payload: UpdatedProductPayload) => queryFn.updateProduct(payload),
	});
}

/**
 * useMutation wrapper for creating a product variation
 */
export function useCreateProductVariation() {
	return useMutation({
		mutationFn: (payload: NewProductVariationPayload) => queryFn.createProductVariation(payload),
	});
}

/**
 * useMutation wrapper for updating a product variation
 */
export function useUpdateProductVariation() {
	return useMutation({
		mutationFn: (payload: UpdatedProductVariationPayload) => queryFn.updateProductVariation(payload),
	});
}



/**
 * useQuery wrapper for fetching the user's cart
 */
export function useCart() {
	return useQuery({
		queryKey: payKeys.cart(),
		queryFn: queryFn.fetchCart
	});
}

/**
 * useMutation wrapper for updating the quantity of a product in the user's cart
 */
export function useRemoveCartItem(productListingId: number) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: () => queryFn.removeFromCart(productListingId),
		onSuccess: () => queryClient.invalidateQueries({
			queryKey: payKeys.cart()
		})
	});
}

/**
 * useMutation wrapper for adding a product listing to the user's cart
 */
export function useAddToCart(productVariationId: number, selectedOptions: Array<{ [x: Option['name']]: Choice }>, quantity: number) {
	return useMutation({
		mutationFn: () => queryFn.addToCart(productVariationId, selectedOptions, quantity),
	});
}

export function useUpdateCartItem(cartItemId: number, quantity: number) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: () => queryFn.updateCartItem(cartItemId, quantity),
		onSuccess: () => queryClient.invalidateQueries({
			queryKey: payKeys.cart()
		})
	});
}

const payKeys = {
	all: ['pay'] as const,

	meta: () => [...payKeys.all, 'meta'] as const,

	listings: () => [...payKeys.all, 'listings'] as const,
	listing: (id: number) => [...payKeys.listings(), 'listing', id] as const,

	products: () => [...payKeys.all, 'products'] as const,
	product: (id: number) => [...payKeys.all, 'product', id] as const,

	cart: () => [...payKeys.all, 'cart'] as const,
};
