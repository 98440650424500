import React from 'react';

interface PayIconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  titleId?: string;
}

function PayIcon({
	title,
	titleId,
	...props
}: PayIconProps, svgRef: React.LegacyRef<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" fill="currentColor">
			<path d="M600,76C310.6,76,76,310.6,76,600c0,289.4,234.6,524,524,524c289.4,0,524-234.6,524-524
	C1124,310.6,889.4,76,600,76z M749.7,849.3c-26.5,33.5-62,51.4-106.5,53.7v72.7h-82.2v-72.7c-45.6-2.3-82.1-20.9-109.5-55.8
	c-27.4-34.9-41.1-76.3-41.1-124.2H516c1.1,28.9,10,51.4,26.4,67.5c16.4,16.2,37.6,24.2,63.6,24.2c21.9,0,40.2-8.1,55-24.2
	c14.7-16.1,22.1-36.4,22.1-60.6c0-8.7-1.2-16.6-3.5-23.8c-2.3-7.2-4.8-13.6-7.4-19c-2.6-5.5-7.2-11-13.8-16.4c-6.6-5.5-12-9.8-16-13
	c-4-3.2-11.1-7.1-21.2-11.7c-10.1-4.6-17.3-7.9-21.6-10c-4.3-2-12.7-5.3-25.1-10c-12.4-4.6-20.6-7.8-24.7-9.5
	c-17.9-7.5-33.2-14.8-45.9-22.1c-12.7-7.2-25.7-17.2-38.9-29.9c-13.3-12.7-23.5-28.6-30.7-47.6c-7.2-19-10.8-41-10.8-65.8
	c0-44.4,12.3-81.6,36.8-111.6c24.5-30,58.1-47,100.8-51.1v-64h82.2v64c41,7.5,72.4,26.1,94.3,55.8c21.9,29.7,33.8,64.8,35.5,105.2
	H671c-2.9-21.9-11.7-39.5-26.4-52.8c-14.7-13.3-31-19.9-48.9-19.9c-19,0-35.2,6.5-48.5,19.5c-13.3,13-19.9,30.4-19.9,52.4
	c0,8.1,1.1,15.4,3.5,22.1c2.3,6.6,6.1,12.7,11.3,18.2c5.2,5.5,10.2,10.3,15.1,14.3c4.9,4,12.1,8.5,21.6,13.4
	c9.5,4.9,17.3,8.7,23.4,11.3c6.1,2.6,15.3,6.4,27.7,11.3c12.4,4.9,21.5,8.5,27.3,10.8c18.5,8.1,34.2,16.2,47.2,24.2
	c13,8.1,26.7,19,41.1,32.9c14.4,13.8,25.4,31.2,32.9,51.9c7.5,20.8,11.3,44.4,11.3,71C789.5,776,776.3,815.8,749.7,849.3z"/>		</svg>
	);
}

const ForwardRef = React.forwardRef(PayIcon);
export default ForwardRef;