import { ReactNode, useState } from 'react';
import { Content } from './components';

interface ModalProps {
    children: (props: { show: boolean; toggleShow: (bool?: boolean) => void }) => JSX.Element;
}
export default function Modal({children}: ModalProps) {
	const [show, setShow] = useState(false);

	const toggleShow = (bool?: boolean) => setShow(bool !== undefined ? bool : !show);

	return children({show, toggleShow});
}

Modal.Content = Content;