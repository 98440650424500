import callAPI from 'services';
import { directoryEndpoints } from 'services/api';
import {
	DirectoryProfile,
	DirectoryMember,
	ProfileCard,
	SensitiveInfo,
	ViewableDirectory,
	UserDirectory, OrganizationDirectory
} from '../types';
import { TODO } from '@types';
import { DirectoryGroup } from '../types/Directory';


export async function directory() {
	return await callAPI<TODO>(directoryEndpoints.directory());
}


export async function fetchDirectoryGroups() {
	return await callAPI<Array<DirectoryGroup>>(directoryEndpoints.directoryGroups());
}


export async function fetchDirectoryGroup(groupId: number) {
	return await callAPI<Array<DirectoryProfile>>(directoryEndpoints.fetchDirectoryGroup(groupId));
}

export async function fetchDirectoryDetails(groupId: number) {
	return await callAPI<Array<DirectoryMember>>(directoryEndpoints.directoryGroupDetails(groupId))
}

export async function fetchDirectoryMembers(groupId: number) {
	return await callAPI<Array<DirectoryMember>>(directoryEndpoints.directoryGroupMembers(groupId))
}


export async function fetchMyContacts(daysBack?: number) {
	return await callAPI<TODO>(directoryEndpoints.myContacts(daysBack));
}


export async function deleteRecentContact(contactId: number) {
	return await callAPI<TODO>(directoryEndpoints.myContact(contactId));
}


export async function fetchProfileCard(profileId: number) {
	return await callAPI<ProfileCard>(directoryEndpoints.profileCard(profileId));
}

export async function fetchSensitiveProfileCardInfo(profileId: number) {
	return await callAPI<SensitiveInfo>(directoryEndpoints.profileCardSensitive(profileId))
}

export async function fetchViewableDirectories(): Promise<Array<ViewableDirectory>> {
	return await callAPI(directoryEndpoints.viewableDirectories());
}

export async function fetchUserDirectories(): Promise<Array<UserDirectory>> {
	return await callAPI(directoryEndpoints.userDirectories());
}

export async function fetchOrganizationDirectories(): Promise<Array<OrganizationDirectory>> {
	return await callAPI(directoryEndpoints.orgDirectories());
}


export async function fetchOrganizationDirectoryProfiles(organizationId: number) {
	return await callAPI<Array<DirectoryMember>>(directoryEndpoints.organizationDirectoryProfiles(organizationId));
}