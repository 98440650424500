import {LanguageDropdown, RegistrationConfirmation} from './components';
import {Form} from '@modules/common/components';
import {useCreateAccount} from '../../hooks';
import {useState} from 'react';
import {useURLQueryParams} from '../../../common/hooks';

export default function Register() {
	const { code } = useURLQueryParams({ code: 'code' });

	if (!code || typeof code === 'number') throw new Error('Invalid registration code');

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	
	const { mutate: createAccount, isError, isSuccess } = useCreateAccount(code);

	function handleSubmit() {
		createAccount({firstName, lastName, phoneNumber, password, password_confirm: passwordConfirm});
	}

	function handleFirstNameChange(e: React.ChangeEvent<HTMLInputElement>) {
		setFirstName(e.target.value);
	}

	function handleLastNameChange(e: React.ChangeEvent<HTMLInputElement>) {
		setLastName(e.target.value);
	}

	function handlePhoneNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
		setPhoneNumber(e.target.value);
	}

	function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
		setPassword(e.target.value);
	}

	function handlePasswordConfirmChange(e: React.ChangeEvent<HTMLInputElement>) {
		setPasswordConfirm(e.target.value);
	}

	if (isSuccess) return <RegistrationConfirmation />;

	return (
		<div className="flex min-h-full flex-1 flex-col justify-center py-12">
			<div className="mx-auto basis-1">
				<div className="mt-10">
					<div className="bg-neutral-90 px-6 py-2 mx-2 rounded-md">
						<div className="flex justify-between h-auto">
							<img
								className='h-24 w-1/3'
								src="/C2.svg"
								alt="Creovia"
							/>
							<div className='ml-10 mt-6'>
								<LanguageDropdown />
							</div>
						</div>
						<div className='text-neutral-5'>
							<h1 className='font-medium mb-2 text-2xl'>Registration</h1>
							<p className='text-md'>Let&apos;s get started with just a few quick steps to create your account.</p>
						</div>
						<div className="mt-8 border-t-2 pt-2 text-neutral-5">
							<div className="mt-4">
								<Form onSubmit={handleSubmit} className='flex flex-col'>
									<input value={firstName} onChange={handleFirstNameChange} className='mb-6 p-2 text-sm w-full bg-neutral-80 placeholder-neutral-40 rounded' placeholder='First Name' />
									<input value={lastName} onChange={handleLastNameChange} className='mb-6 p-2 text-sm w-full bg-neutral-80 placeholder-neutral-40 rounded' type={'tel'} placeholder='Last Name' />
									<input value={phoneNumber} onChange={handlePhoneNumberChange} className='mb-6 p-2 text-sm w-full bg-neutral-80 placeholder-neutral-40 rounded' placeholder='Primary Phone' />

									<input
										value={password} onChange={handlePasswordChange}
										id="password"
										name="password"
										type="password"
										autoComplete="current-password"
										required

										className='p-2 text-sm w-full bg-neutral-80 placeholder-neutral-40 rounded'
										placeholder="Password"
										style={{height: '30px'}}
									/>
									<div className="mb-6 italic text-xs tracking-tight dark:text-white mt-2">
                                            Passwords should be a minimum of 8 characters, with no spaces.
									</div>


									<input
										value={passwordConfirm} onChange={handlePasswordConfirmChange}
										id="password_confirm"
										name="password_confirm"
										type="password"
										autoComplete="current-password"
										required

										className='p-2 text-sm w-full bg-neutral-80 placeholder-neutral-40 rounded'
										placeholder="Confirm your password"
										style={{height: '30px'}}
									/>
									<div className="mb-6 italic text-xs tracking-tight dark:text-white mt-2">
                                            Enter the same password as before, for verification.
									</div>

									<button className='px-4 py-2 bg-primary-40 my-4 w-full flex justify-center text-neutral-90 rounded'>Register</button>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	);

}