import { magnifyEndpoints } from 'services/api';

import callAPI from 'services';
import { DashboardResponseType, DashboardCardType, StudentStatus, StudentBehavior, StudentGrades, StudentAssessments } from './types/DashboardTypes';

const fetchDashboardList = async <T>(): Promise<DashboardResponseType<T>> => {
    return await callAPI<DashboardResponseType<T>>(magnifyEndpoints.dashboardList, {});
};

const fetchRecentList = async <T>(): Promise<DashboardResponseType<T>> => {
    return await callAPI<DashboardResponseType<T>>(magnifyEndpoints.recentDashboards, {});
};

const fetchDashboard = async <T>(dashboardID: string): Promise<DashboardResponseType<T>> => {
    return await callAPI<DashboardResponseType<T>>(`${magnifyEndpoints.dashboard}${dashboardID}`, {});
};

// const fetchDashboardCard = async (cardURL: string): Promise<DashboardCardType> => {
// 	return await callAPI<DashboardCardType>(`${magnifyEndpoints.dashboardCard}${cardURL}`, {});
// };

const fetchStudentStatus = async (studentId: number): Promise<StudentStatus> => {
    return await callAPI(magnifyEndpoints.dashboardCardStatus(studentId));
};

const fetchStudentBehavior = async (studentId: number): Promise<Array<StudentBehavior>> => {
    return await callAPI(magnifyEndpoints.dashboardCardBehavior(studentId));
};

const fetchStudentGrades = async (studentId: number): Promise<Array<StudentGrades>> => {
    return await callAPI(magnifyEndpoints.dashboardCardGrades(studentId));
};

const fetchStudentAssessments = async (studentId: number): Promise<Array<StudentAssessments>> => {
    return await callAPI(magnifyEndpoints.dashboardCardAssessments(studentId));
};

export { fetchDashboardList, fetchRecentList, fetchDashboard, fetchStudentStatus, fetchStudentBehavior, fetchStudentGrades, fetchStudentAssessments };
