import {Tokens, User} from '@types';
import {refreshAccessToken} from '../axiosInstance';
import callAPI from '../index';
import { userEndpoints } from './endpoints';

export const refresh = async (): Promise<Tokens> => {
	return await refreshAccessToken();
};

export async function getUser(): Promise<User> {
	return await callAPI<User>(userEndpoints.user);
}