import {Combobox} from '@headlessui/react';

interface ButtonProps {
    children: JSX.Element;
}
export default function Button({ children, ...props }: ButtonProps) {
	return (
		<Combobox.Button {...props}>
			{children}
		</Combobox.Button>
	);
}