import { useState, useEffect } from 'react';

export default function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState('up');

    useEffect(() => {

        const middleColumn = document.getElementById('middle-column');

        if (!middleColumn) {
            return;
        }

        let lastScrollTop = middleColumn.scrollTop;

        const handleScroll = () => {
            const currentScrollTop = middleColumn.scrollTop;

            if (currentScrollTop > lastScrollTop) {
                setScrollDirection('down');
            } else if (currentScrollTop < lastScrollTop) {
                setScrollDirection('up');
            }

            lastScrollTop = currentScrollTop;
        };

        middleColumn.addEventListener('scroll', handleScroll);

        return () => {
            middleColumn.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollDirection;
}