import { createSlice } from "@reduxjs/toolkit";
import { ObservationType } from "@modules/perform/types";

export interface PerformState {
  observation: ObservationType | null;
  observation_id: number | null;
  lastSave: Date | null;
  currentCategoryIndex: number;
}

export const initialPerformState = (): PerformState => {
  return {
    observation: null,
    observation_id: null,
    lastSave: null,
    currentCategoryIndex: 0,
  };
};

export const performSlice = createSlice({
  name: "perform",
  initialState: initialPerformState(),
  reducers: {
    updateObservation: (state, action: { payload: ObservationType }) => {
      state.observation = action.payload;
    },
    setObservation: (state, action: { payload: ObservationType }) => {
      state.observation = action.payload;
      state.observation_id = action.payload.id;
    },
    clearObservationId: (state) => {
      state.observation_id = null;
      state.currentCategoryIndex = 0;
    },
    clearObservation: (state) => {
      state.observation = null;
      state.currentCategoryIndex = 0;
    },
    setCurrentCategory: (state, action: { payload: number }) => {
      state.currentCategoryIndex = action.payload;
    },
  },
});
export const { updateObservation, setObservation, clearObservation, setCurrentCategory, clearObservationId } = performSlice.actions;
export default performSlice.reducer;
