import {ComponentPropsWithoutRef, PropsWithChildren, ReactNode} from 'react';
import {Listbox, ListboxProps} from '@headlessui/react';
import { Button, Options, Option } from './components';

interface ListboxWrapperProps<T> extends PropsWithChildren<ComponentPropsWithoutRef<typeof Listbox>> {
	selected: T | undefined;
	disabled?: boolean;
	onChange: (selected: T) => void;
	children: ReactNode;
}

export default function Selector<T>({selected, onChange, children, disabled, className, ...props}: ListboxWrapperProps<T>) {

	return (
		<Listbox as={'div'} value={selected} onChange={onChange} disabled={disabled} className={'relative disabled-opacity-20 ' + className} {...props}>
			{children}
		</Listbox>
	);

}

Selector.Button = Button;
Selector.Options = Options;
Selector.Option = Option;