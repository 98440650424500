import { createSlice } from '@reduxjs/toolkit';

export interface SearchState {
	query: string,
}

export const initialSearchState = (): SearchState => {
	return {
		query: ''
	};
};

export const searchSlice = createSlice({
	name: 'search',
	initialState: initialSearchState(),
	reducers: {
		setSearchQuery: (state, action: { payload: string }) => {
			state.query = action.payload;
		},
		clearSearchQuery: (state) => {
			state.query = '';
		}
	}
});

export const { setSearchQuery, clearSearchQuery } = searchSlice.actions;
export default searchSlice.reducer;