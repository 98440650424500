import callAPI, {PaginatedResponse} from 'services';
import { payEndpoints } from 'services/api';
import { Listing, Listings } from '@modules/pay/types';
import { newListingSchema, NewListingPayload, updatedListingSchema, UpdatedListingPayload } from '@modules/pay/schemas';

/**
 * Fetches the list of products
 */
export async function fetchListings() {
	return await callAPI<Listings>(payEndpoints.listings);
}

/**
 * Fetches a product listing
 */
export async function fetchListing(id: number) {
	return await callAPI<Listing>(payEndpoints.listing(id));
}


/**
 * Creates a new product listing
 */
export async function createListing(payload: NewListingPayload) {
	await newListingSchema.parse(payload);

	return await callAPI(payEndpoints.listings, {
		method: 'POST',
		body: JSON.stringify(payload),
	});
}

/**
 * Updates a product listing
 */
export async function updateListing(payload: UpdatedListingPayload) {
	await updatedListingSchema.parse(payload);

	return await callAPI(payEndpoints.listing(payload.id), {
		method: 'PUT',
		body: JSON.stringify(payload),
	});
}

/**
 * Deletes a product listing
 */
export async function deleteListing(productListingId: number) {
	return await callAPI(payEndpoints.listing(productListingId), {
		method: 'DELETE',
	});
}
